/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState, ReactNode } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";

import SidenavCollapse from "layouts/Sidenav/SidenavCollapse";
import SidenavItem from "layouts/Sidenav/SidenavItem";
import SidenavList from "layouts/Sidenav/SidenavList";
import SidenavRoot from "layouts/Sidenav/SidenavRoot";
import { IconButton } from "@mui/material";
import { navbarDesktopMenu } from "layouts/DashboardNavbar/styles";
import {
  RoutePath,
  SAVED_FILTERS_KEY,
  SAVED_SEARCH_KEY,
  breadcrumbConfig,
} from "../../constants";
import breakpoints from "assets/theme/base/breakpoints";
import {
  SAVED_FILTERS_BATCH_KEY,
  SAVED_SEARCH_BATCH_KEY,
} from "@ivueit/vue-engine";

// Declaring props types for Sidenav
interface Props {
  color?:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark";
  brand?: string;
  brandName: string;
  routes: {
    [key: string]:
      | ReactNode
      | string
      | {
          [key: string]:
            | ReactNode
            | string
            | {
                [key: string]: ReactNode | string;
              }[];
        }[];
  }[];
  [key: string]: any;
}

function Sidenav({
  color,
  brand,
  brandName,
  routes,
  ...rest
}: Props): JSX.Element {
  const [openCollapse, setOpenCollapse] = useState<boolean | string>(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState<
    boolean | string
  >(false);
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;

  const location = useLocation();
  const { pathname } = location;
  const items = pathname.split("/").slice(1);
  const collapseName = breadcrumbConfig[items[0]]?.collapseName;
  const itemParentName =
    items.length > 1 && !items[1].isNumber()
      ? items[1]
      : breadcrumbConfig[items[0]]?.itemParentName;
  const itemName = items[items.length - 1];

  let textColor:
    | "primary"
    | "secondary"
    | "info"
    | "success"
    | "warning"
    | "error"
    | "dark"
    | "white"
    | "inherit"
    | "text"
    | "light" = "white";
  if (whiteSidenav || transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }

  useEffect(() => {
    setOpenCollapse(collapseName);
    setOpenNestedCollapse(itemParentName);
  }, [collapseName, itemParentName]);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      const showMiniNavBar =
        window.localStorage.getItem("MINI_SIDENAV") === "true";
      if (!showMiniNavBar) {
        // Adjusting width only if the mini side nav is not set
        setMiniSidenav(dispatch, window.innerWidth < breakpoints.values.md);
      }
      setTransparentSidenav(
        dispatch,
        window.innerWidth < breakpoints.values.md ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < breakpoints.values.md ? false : whiteSidenav
      );
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location, transparentSidenav, whiteSidenav]);

  const resetStoredFilters = () => {
    window.localStorage.removeItem(SAVED_FILTERS_KEY);
    window.localStorage.removeItem(SAVED_SEARCH_KEY);
    window.localStorage.removeItem(SAVED_FILTERS_BATCH_KEY);
    window.localStorage.removeItem(SAVED_SEARCH_BATCH_KEY);
  };

  const getDivider = (key?: string | null) => {
    return (
      <Divider
        key={key}
        sx={{
          backgroundColor: "#ffffff",
          height: "1px",
          marginX: "15pt",
          opacity: "1",
        }}
      />
    );
  };

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse: any) => {
    const template = collapse.map(({ name, route, icon, key, href }: any) => {
      return href ? (
        <div key={key}>
          <Link
            key={key}
            href={href}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem
              name={name}
              icon={icon}
              nested
              onClick={({ currentTarget }: any) => {
                resetStoredFilters();
              }}
            />
          </Link>
        </div>
      ) : (
        <div key={key}>
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem
              name={name}
              icon={icon}
              active={route === pathname || pathname.includes(route)}
              nested
              onClick={({ currentTarget }: any) => {
                resetStoredFilters();
              }}
            />
          </NavLink>
        </div>
      );
    });
    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses: any) =>
    collapses.map(({ name, collapse, icon, route, href, key }: any) => {
      let returnValue;
      if (collapse && route) {
        returnValue = (
          <NavLink
            to={route}
            key={key + route}
            style={{ textDecoration: "none" }}
          >
            <SidenavItem
              key={key}
              icon={icon}
              color={color}
              name={name}
              // active={ key === itemParentName ? "isParent" : false}
              // Checking whether the item collapsible item is selcted or not
              active={
                key === itemName
                  ? true
                  : key === itemParentName
                  ? "isParent"
                  : false
              }
              open={openNestedCollapse === key}
              onClick={({ currentTarget }: any) => {
                resetStoredFilters();
                openNestedCollapse === key &&
                currentTarget.classList.contains("MuiListItem-root")
                  ? setOpenNestedCollapse(false)
                  : setOpenNestedCollapse(key);
              }}
            >
              {renderNestedCollapse(collapse)}
            </SidenavItem>
          </NavLink>
        );
      } else if (collapse) {
        returnValue = (
          <SidenavItem
            key={key}
            icon={icon}
            color={color}
            name={name}
            active={key === itemParentName ? "isParent" : false}
            open={openNestedCollapse === key}
            onClick={({ currentTarget }: any) => {
              resetStoredFilters();
              openNestedCollapse === key &&
              currentTarget.classList.contains("MuiListItem-root")
                ? setOpenNestedCollapse(false)
                : setOpenNestedCollapse(key);
            }}
          >
            {renderNestedCollapse(collapse)}
          </SidenavItem>
        );
      } else {
        returnValue = href ? (
          <Link
            href={href}
            key={key}
            target="_blank"
            rel="noreferrer"
            sx={{ textDecoration: "none" }}
          >
            <SidenavItem
              color={color}
              icon={icon}
              name={name}
              active={key === itemName}
              onClick={({ currentTarget }: any) => {
                resetStoredFilters();
              }}
            />
          </Link>
        ) : (
          <NavLink to={route} key={key} style={{ textDecoration: "none" }}>
            <SidenavItem
              color={color}
              icon={icon}
              name={name}
              active={key === itemName || pathname.includes(route)}
              onClick={({ currentTarget }: any) => {
                resetStoredFilters();
              }}
            />
          </NavLink>
        );
      }
      return <SidenavList key={key}>{returnValue}</SidenavList>;
    });

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
    }: any) => {
      let returnValue;

      if (type === "collapse") {
        if (href) {
          returnValue = (
            <Link
              href={href}
              key={key}
              target="_blank"
              rel="noreferrer"
              sx={{ textDecoration: "none" }}
            >
              <SidenavCollapse
                name={name}
                icon={icon}
                active={key === collapseName}
                noCollapse={noCollapse}
              />
            </Link>
          );
        } else if (noCollapse && route) {
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                name={name}
                icon={icon}
                noCollapse={noCollapse}
                active={key === collapseName || pathname.includes(route)}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else if (collapse && route) {
          // Added to handle items with collpase & route
          returnValue = (
            <NavLink to={route} key={key}>
              <SidenavCollapse
                key={key}
                name={name}
                icon={icon}
                active={key === collapseName}
                open={openCollapse === key}
                onClick={() => {
                  resetStoredFilters();
                  openCollapse === key
                    ? setOpenCollapse(false)
                    : setOpenCollapse(key);
                }}
              >
                {collapse ? renderCollapse(collapse) : null}
              </SidenavCollapse>
            </NavLink>
          );
        } else {
          returnValue = (
            <SidenavCollapse
              key={key}
              name={name}
              icon={icon}
              active={key === collapseName}
              open={openCollapse === key}
              onClick={() => {
                resetStoredFilters();
                openCollapse === key
                  ? setOpenCollapse(false)
                  : setOpenCollapse(key);
              }}
            >
              {collapse ? renderCollapse(collapse) : null}
            </SidenavCollapse>
          );
        }
      } else if (type === "title") {
        returnValue = (
          <MDTypography
            key={key}
            color={textColor}
            display="block"
            variant="caption"
            fontWeight="bold"
            textTransform="uppercase"
            pl={3}
            mt={2}
            mb={1}
            ml={1}
          >
            {title}
          </MDTypography>
        );
      } else if (type === "divider") {
        returnValue = getDivider(key);
      } else {
        // added new default return value
        returnValue = (
          <SidenavCollapse
            key={key}
            name={name}
            icon={icon}
            active={key === collapseName}
            open={openCollapse === key}
            noCollapse={noCollapse}
            onClick={() => {
              resetStoredFilters();
              openCollapse === key
                ? setOpenCollapse(false)
                : setOpenCollapse(key);
            }}
          >
            {collapse ? renderCollapse(collapse) : null}
          </SidenavCollapse>
        );
      }

      return returnValue;
    }
  );

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { white } }: { palette: any }) => ({
    color: white.main,
  });

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
    >
      <MDBox pt={2} px={2} textAlign={miniSidenav ? "center" : "left"}>
        <IconButton
          sx={navbarDesktopMenu}
          onClick={handleMiniSidenav}
          size="small"
          disableRipple
        >
          <Icon fontSize="medium" sx={iconsStyle}>
            {miniSidenav ? "menu" : "menu_open"}
          </Icon>
        </IconButton>
      </MDBox>
      <MDBox pt={1} pb={1} px={3} textAlign="center">
        <MDBox
          component={NavLink}
          to={RoutePath.vueDashboard}
          display="flex"
          alignItems="center"
        >
          {brand && (
            <MDBox
              component="img"
              src={brand}
              alt="Brand"
              width="100%"
              display="block"
            />
          )}
        </MDBox>
      </MDBox>
      {getDivider(null)}
      <List>{renderRoutes}</List>
    </SidenavRoot>
  );
}

// Declaring default props for Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

export default Sidenav;
