import { useRef, useState, useEffect, useReducer, useCallback } from "react";
import * as VueGridImport from "./vue-grid/helpers/imports";
import { useAuth } from "context/AuthProvider";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import {
  DataGridPremiumProps,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridRenderCellParams,
  GridRowId,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import {
  defaultMetaData,
  defaultVueStatusCount,
  initialVisibilityModel,
  pinnedColumnHeaders,
  pinnedColumnIds,
} from "./vue-grid/types/constants";
import { HeaderSortIconButton } from "./vue-grid/components/HeaderSortIconButton";
import {
  DashboardVue,
  VueStatusCount,
  VuesPageAPIMetaData,
} from "../dashboard-home/utils/dashboard_interfaces";
import { Company } from "pages/profile/utils/ProfileInterfaces";
import {
  VuesPageActionType,
  VuesPageSummaryState,
  defaultVuesPageSummaryState,
  vuesPageSummaryReducer,
} from "./vue-grid/utils/reducer";
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  RoutePath,
  SAVED_FILTERS_KEY,
  SAVED_SEARCH_KEY,
  TEXTFIELD_CHANGE_DELAY,
  TOOLTIP_HOVER_DELAY,
} from "../../../../constants";
import {
  areArraysEqual,
  containsReadOnlyVuesInList,
  filterDateToNanoSecondsString,
  generateGridAPIParamter,
  getCustomColumnDefinitionForField,
  getGridDataFromDashboardVue,
  getPreviouslySearchedText,
  scrollToTop,
} from "./vue-grid/helpers/helper_methods";
import {
  getAllMediaTagNameToFilter,
  getAllPhotoEscalationReasonsByVueID,
  getAllVueTagNameToFilter,
  getClientVues,
  reissueVues,
  setVuesAsUrgent,
} from "./services/VueServices";
import moment, { Moment } from "moment";
import { WebServiceStatus } from "utils/services/AppUrls";
import { checkBoxStyle, searchBarStyle } from "./styles/VueStyles";
import FilterByTextContent from "./vue-grid/components/FilterByTextContent";
import FilterByTimeContent from "./vue-grid/components/FilterByTimeContent";
import SingleSelectionListFilter from "./vue-grid/components/SingleSelectionListFilter";
import {
  getMakeUrgentDialog,
  getReIssueDialog,
  getCancelDialog,
  getExportDialog,
} from "./vue-grid/helpers/action_bar_dialogs";
import {
  timePickerStyledPoppers,
  textFieldStyledPoppers,
  rangeStyledPoppers,
  BatchItem,
} from "./vue-grid/types/types";
import {
  getSurveyTypeFilterOptions,
  getSurveyTypeStringValue,
  getVueStatusFilterOptions,
  getStatusStringValue,
  getSurveyTypeFilterValue,
  getVueStatusFilterValue,
  immutableVueTagList,
} from "./vue-grid/utils/enums";
import { ScrollToTopButton } from "./vue_detail/components/ScrollToTopButton";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import { useLocalStorage } from "hooks/useLocalStorage";
import { alphanumericSort } from "utils/helpers/extensions";
import { DataGridType } from "../common/types/types";
import {
  getPreferencesForGrid,
  setColumnOrderAndVisibilityForGrid,
  setColumnWidthForGrid,
} from "../common/services/services";
import { Typography } from "@mui/material";
import { SurveyScoreGridHeader } from "pages/components/SurveyScoreGridHeader";
import {
  FilterByRangeContent,
  gridStyle,
  CustomIndicator,
  CustomDialogBox,
  AssignSiteNameDialog,
} from "@ivueit/vue-engine";
import { UserRoles } from "pages/my-account/manage-users/interfaces/interfaces";
import { hasMinimumRequiredRole } from "utils/helpers/common";

var allColumnDefinition: VueGridImport.GridColDef[] =
  VueGridImport.defaultColumnDefinition;

const Vues = () => {
  const mounted = useRef(null);
  const location = useLocation();
  const [loader, setLoader] = useState(false);
  const [columnDefinition, setColumnDefinition] =
    useState<VueGridImport.GridColDef[]>(allColumnDefinition);
  const [rows, setRows] = useState<VueGridImport.GridData[]>([]);
  const [vueStatusCount, setVueStatusCount] = useState<VueStatusCount>(
    defaultVueStatusCount
  );
  const [didUseSelectAllOption, setDidUseSelectAllOption] =
    useState<boolean>(false);
  const [excludedVueIds, setExcludedVueIds] = useState<string[]>([]);
  // Contains the list of meta column names
  const [metaColumnNames, setMetaColumnNames] = useState<string[]>([]);
  /// In the initial render we will get the whole set of meta column names & used a reference object to store it
  const setOfMetaColumns = useRef<string[]>([]);
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const { getAvailablePortalsForUser, userData, userRole } = useAuth();
  // Sets the companyId
  const parentCompanyId = userData.companyId;
  const availableCompanyList: Company[] = getAvailablePortalsForUser();
  const [storedFilters, setStoredFilters] = useLocalStorage(
    SAVED_FILTERS_KEY,
    []
  );
  const [storedSearchText, setStoredSearchText] = useLocalStorage(
    SAVED_SEARCH_KEY,
    ""
  );
  const [allVueTagNames, setAllVueTagNames] = useState<string[]>([]);
  const [allMediaTagNames, setAllMediaTagNames] = useState<string[]>([]);
  const [metaValues, setMetaValues] =
    useState<VuesPageAPIMetaData>(defaultMetaData);
  /// This controls and manages the selection of rows in the grid
  const [selectedRowsList, setSelectedRowsList] =
    useState<VueGridImport.GridRowSelectionModel>([]);
  /// Stores the column model
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<VueGridImport.GridColumnVisibilityModel>(initialVisibilityModel);
  /// Sort model for the grid
  const [sortModel, setSortModel] = useState<VueGridImport.GridSortModel>([]);
  /// Handles the state of checkbox in the topbar
  const [selectAllChecked, setSelectAllChecked] = useState<boolean>(false);
  /// Holds the list of selected filters
  const [selectedFilters, setSelectedFilters] = useState<any[]>(storedFilters);
  const [clientVuesState, dispatchClientsVueFetchAction] = useReducer(
    vuesPageSummaryReducer,
    defaultVuesPageSummaryState
  );
  /// Stores the latest added columns
  const [visibleColumnsList, setVisibleColumnsList] = useState<string[]>();
  const navigate = useNavigate();
  /// Holds the state of search field
  const [searchInput, setSearchInput] = useState<string>(storedSearchText);
  /// Defines the anchor element for the popup
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement>(null);
  /// Defines the popup fields
  const [popperName, setPopperName] = useState<string>(null);
  const [hasLocationState, setHasLocationState] = useState<boolean>(false);
  const [showCustomIndicator, setShowCustomIndicator] = useState(false);
  /// States for handling action bar dialogs
  const [showReIssuePopup, setShowReIssuePopup] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState<boolean>(false);
  const [showExportPopup, setShowExportPopup] = useState<boolean>(false);
  const [showMakeUrgentPopup, setShowMakeUrgentPopup] =
    useState<boolean>(false);
  const [columnWidthObject, setColumnWidthObject] = useState<{
    columnName: string;
    columnWidth: number;
  }>();
  const [gridData, setGridData] = useState<VueGridImport.GridData[]>([]);
  const gridRef = useGridApiRef();
  const userTimeZone = moment.tz.guess();
  /// Manage Column Popper content
  const editableColumnNames = columnDefinition
    .filter((column) => !pinnedColumnIds.includes(column.field))
    .sort((a, b) => alphanumericSort(a.headerName, b.headerName))
    .map((item) => item.headerName);
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  const [escalationReasonsList, setEscalationReasonsList] = useState<string[]>(
    []
  );
  const [hoveredVueId, setHoveredVueId] = useState<string>("");
  const [selectedVueForSiteName, setSelectedVueForSiteName] = useState<
    any | null
  >(null);
  const getFooter = () => {
    return VueGridImport.generateFooterElement(vueStatusCount);
  };
  const shouldDisableCancelButton = VueGridImport.disableCancelButton(
    rows,
    selectedRowsList
  );
  const shouldDisableReissueButton = VueGridImport.disableReissueButton(
    rows,
    selectedRowsList
  );
  const shouldDisableMakeUrgentButton = VueGridImport.disableMakeUrgentButton(
    rows,
    selectedRowsList
  );

  const hasReadOnlyVues = containsReadOnlyVuesInList(
    rows,
    selectedRowsList,
    availableCompanyList
  );

  useEffect(() => {
    if (location.state) {
      /// This helps us to know that we have some location state object
      setHasLocationState(true);
      const startDate: string = location.state.startDate;
      const endDate: string = location.state.endDate;
      const status: string = location.state.status;
      const surveyType: string = location.state.surveyType;
      const escalated: boolean = location.state.escalated;
      const companyIds: string[] = location.state.companyIds;
      const siteNameNumber: string = location.state.siteNameNumber;
      const batchID: string = location.state.batchID;
      const completedAtStartDate: string = location.state.completedAtStartDate;
      const completedAtEndDate: string = location.state.completedAtEndDate;
      const surveyTemplateId: string = location.state.surveyTemplateId;

      if (escalated) {
        if (startDate && endDate) {
          dispatchClientsVueFetchAction({
            type: VuesPageActionType.listEscalatedByDate,
            payload: {
              ...clientVuesState,
              createdAtStartDate: startDate,
              createdAtEndDate: endDate,
              escalated,
              companyIds,
            },
          });
        } else {
          dispatchClientsVueFetchAction({
            type: VuesPageActionType.listEscalatedByDate,
            payload: {
              ...clientVuesState,
              escalated,
              companyIds,
            },
          });
        }
      } else if (status && !surveyType) {
        const statusIndex = getVueStatusFilterValue(status);
        if (startDate && endDate) {
          dispatchClientsVueFetchAction({
            type: VuesPageActionType.listBasedFilterApplied,
            payload: {
              ...clientVuesState,
              statuses: [statusIndex],
              createdAtStartDate: startDate,
              createdAtEndDate: endDate,
              companyIds,
            },
          });
        } else {
          dispatchClientsVueFetchAction({
            type: VuesPageActionType.listBasedFilterApplied,
            payload: {
              ...clientVuesState,
              statuses: [statusIndex],
              companyIds,
            },
          });
        }
      } else if (surveyType) {
        const statusIndex = getVueStatusFilterValue(status);
        const typeIndex = getSurveyTypeFilterValue(surveyType);
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.listBasedFilterApplied,
          payload: {
            ...clientVuesState,
            types: [typeIndex],
            statuses: [statusIndex],
            createdAtStartDate: startDate,
            createdAtEndDate: endDate,
            companyIds,
          },
        });
      } else if (startDate && endDate) {
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.createdDateChanged,
          payload: {
            ...clientVuesState,
            createdAtStartDate: startDate,
            createdAtEndDate: endDate,
            companyIds,
          },
        });
      } else if (completedAtStartDate && completedAtEndDate) {
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.completedDateChanged,
          payload: {
            ...clientVuesState,
            completedAtStartDate: completedAtStartDate,
            completedAtEndDate: completedAtEndDate,
            companyIds,
          },
        });
      } else if (siteNameNumber) {
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.siteSelectedChanged,
          payload: {
            ...clientVuesState,
            siteNameAndNumber: siteNameNumber,
          },
        });
      } else if (batchID) {
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.batchSelectedChanged,
          payload: {
            ...clientVuesState,
            batchID: batchID,
          },
        });
      } else if (surveyTemplateId) {
        dispatchClientsVueFetchAction({
          type: VuesPageActionType.surveySelectedChanged,
          payload: {
            ...clientVuesState,
            surveyTemplateId: surveyTemplateId,
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state, hasLocationState]);

  useEffect(() => {
    const updatePayload = () => {
      var payload: VuesPageSummaryState = {
        ...defaultVuesPageSummaryState,
        search: searchInput,
      };
      // Traversing through each filters and updating the reducer
      selectedFilters.forEach((filter) => {
        const { headerName, filters } = filter;
        switch (headerName) {
          case "createdDate":
            payload = {
              ...payload,
              createdAtStartDate: filters[0],
              createdAtEndDate: filters[1],
            };
            break;
          case "completedDate":
            payload = {
              ...payload,
              completedAtStartDate: filters[0],
              completedAtEndDate: filters[1],
            };
            break;
          case "completedTime":
            payload = {
              ...payload,
              completedAtStartTime: filters[0],
              completedAtEndTime: filters[1],
            };
            break;
          case "slaTarget":
            payload = {
              ...payload,
              slaTargetMin: filters[0],
              slaTargetMax: filters[1],
            };
            break;
          case "slaActual":
            payload = {
              ...payload,
              slaActualMin: filters[0],
              slaActualMax: filters[1],
            };
            break;
          case "surveyType":
            const surveyTypes = filters.map((typeName: string) =>
              getSurveyTypeFilterValue(typeName)
            );
            payload = {
              ...payload,
              types: surveyTypes,
            };
            break;
          case "status":
            const vueStatuses = filters.map((typeName: string) =>
              getVueStatusFilterValue(typeName)
            );
            payload = {
              ...payload,
              statuses: vueStatuses,
            };
            break;
          case "clientName":
            // Finding company ids for the selected company name
            const companyIds = availableCompanyList
              .filter((company) => filters.includes(company.name))
              .map((company) => company.id);
            payload = {
              ...payload,
              companyIds: companyIds,
            };
            break;
          case "hasEscalated":
            if (filters.length > 0) {
              const escalated = filters[0] === "Yes";
              payload = {
                ...payload,
                escalated: escalated,
              };
            }
            break;
          case "vueTags":
            payload = {
              ...payload,
              vueTags: filters,
            };
            break;
          case "photoTags":
            payload = {
              ...payload,
              mediaTags: filters,
            };
            break;
          case "country":
            payload = {
              ...payload,
              countryAbbreviation: filters,
            };
            break;
          case "vueId":
            payload = {
              ...payload,
              canonicalID: filters[0],
            };
            break;
          case "vueName":
            payload = {
              ...payload,
              vueTitle: filters[0],
            };
            break;
          case "siteName":
            payload = {
              ...payload,
              siteNameAndNumber: filters[0],
            };
            break;
          case "address":
            payload = {
              ...payload,
              postalAddress: filters[0],
            };
            break;
          case "address2":
            payload = {
              ...payload,
              addressTwo: filters[0],
            };
            break;
          case "state":
            payload = {
              ...payload,
              stateProvinceAbbreviation: filters[0],
            };
            break;
          case "city":
            payload = {
              ...payload,
              city: filters[0],
            };
            break;
          case "zipCode":
            payload = {
              ...payload,
              zipPostalCode: filters[0],
            };
            break;
          default:
            payload = {
              ...payload,
              metaKeySearches: {
                ...payload.metaKeySearches,
                [headerName]: filters[0],
              },
            };
            break;
        }
      });
      dispatchClientsVueFetchAction({
        type: VuesPageActionType.filterApplied,
        payload: payload,
      });
    };

    if (selectedFilters.length > 0) {
      updatePayload();
    } else {
      var payload: VuesPageSummaryState = { ...defaultVuesPageSummaryState };
      // Updating search input to avoid it getting cleared on filter removal, fixes CP-1260
      payload = { ...payload, search: searchInput };
      setStoredFilters([]);
      // Clearing the filter.
      dispatchClientsVueFetchAction({
        type: VuesPageActionType.clearAllFilterClicked,
        payload: payload,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFilters]);

  const getAssignSiteNameDialog = () => {
    if (!selectedVueForSiteName) {
      return <></>;
    }

    let selectedSiteData: { siteNameNumber: string; siteId: string } | null =
      null;
    const { vueId, id, jobSiteId, siteNameNumber } = selectedVueForSiteName;
    if (jobSiteId && siteNameNumber && jobSiteId?.isNotEmpty()) {
      selectedSiteData = { siteNameNumber: siteNameNumber, siteId: jobSiteId };
    }

    return (
      <CustomDialogBox
        title="Assign Site Name"
        width="400px"
        openDialog={selectedVueForSiteName !== null}
      >
        <AssignSiteNameDialog
          vueId={id}
          canonicalId={vueId}
          parentCompanyId={parentCompanyId}
          previouslySelectedSite={selectedSiteData}
          onClickCancel={() => {
            setSelectedVueForSiteName(null);
          }}
          onClickAssign={async () => {
            setSelectedVueForSiteName(null);
            fetchVueListFromServer(false, false);
          }}
        />
      </CustomDialogBox>
    );
  };

  /********************* COLUMN HEADER SECTION ***********************/

  /// Generates custom icon buttons in the column header
  const getHeader = (params: VueGridImport.GridColumnHeaderParams) => {
    const { colDef, field } = params;
    const headerName = colDef.headerName;
    const showRearrangeIcon = !pinnedColumnHeaders.includes(headerName);
    const showSortIcon =
      headerName !== "Vue Tags" &&
      headerName !== "Photo Tags" &&
      headerName !== "Latitude" &&
      headerName !== "Longitude";
    const showFilterIcon =
      headerName !== "Latitude" && headerName !== "Longitude";
    return (
      <>
        {field === "surveyScore" && <SurveyScoreGridHeader />}
        <strong>{headerName.toUpperCase()}</strong>
        {showSortIcon && (
          <HeaderSortIconButton
            onClickSort={(
              isAscending: boolean,
              newSortModel: VueGridImport.GridSortModel
            ) => {
              /// Adding a prefix "Custom-" if this is a meta data column
              const isNonMetaColumn = allColumnDefinition.some(
                (column) =>
                  column.headerName.toUpperCase() === headerName.toUpperCase()
              );
              setSortColumn(
                isNonMetaColumn ? headerName : `Custom-${headerName}`,
                !isAscending
              );
              setSortModel(newSortModel);
            }}
            sortModel={sortModel}
            field={field}
          />
        )}
        {showFilterIcon && (
          <VueGridImport.IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              setAnchorElement(event.currentTarget);
              handleFilterIconClick(field);
            }}
          >
            <VueGridImport.Icon path={VueGridImport.mdiFilter} size={0.72} />
          </VueGridImport.IconButton>
        )}
        {showRearrangeIcon && (
          <VueGridImport.IconButton
            size="small"
            sx={{ ml: "auto", mr: "10px" }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            <VueGridImport.Icon
              path={VueGridImport.mdiCursorMove}
              size={0.72}
            />
          </VueGridImport.IconButton>
        )}
      </>
    );
  };

  /// Handles the navigation to vue detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    const id = params.row.vueId;
    saveSelectedFiltersAndSearchOnNavigation();
    // navigating to the detail page
    navigate(`${RoutePath.vues}/${id}`);
  };

  const saveSelectedFiltersAndSearchOnNavigation = () => {
    // Saving filters on navigation
    setStoredFilters(selectedFilters);
    setStoredSearchText(searchInput);
  };

  /// Handles the filter the vues with selected tag
  const onSelectVueTag = (tagSelected: string) => {
    const filterObject = { headerName: "vueTags", filters: [tagSelected] };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.headerName === "vueTags"
    );
    var updatedFilters = [...selectedFilters];
    if (indexOfItem !== -1) {
      updatedFilters[indexOfItem] = filterObject;
    } else {
      updatedFilters.push(filterObject);
    }
    setSelectedFilters(updatedFilters);
  };

  useEffect(() => {
    const onHoverEscalatedChip = async () => {
      if (hoveredVueId) {
        let reasonsList: string[];
        const response = await getAllPhotoEscalationReasonsByVueID(
          hoveredVueId
        );
        if (response.status === WebServiceStatus.success) {
          const responseData = response.data;
          const { reasons }: { reasons: string[] } = responseData;
          const validReasons = reasons.filter((reason) => reason);
          if (validReasons.length === 0) {
            reasonsList = ["No reasons available"];
          } else {
            reasonsList = validReasons;
          }
          setEscalationReasonsList(reasonsList);
        } else {
          setSnackbarContent({
            title: "Escalation Reasons",
            message: "Unable to fetch the escalation reason.",
            isError: true,
          });
        }
      }
    };

    const delayTooltipHoverAction = setTimeout(() => {
      onHoverEscalatedChip();
    }, TOOLTIP_HOVER_DELAY);
    return () => clearTimeout(delayTooltipHoverAction);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoveredVueId]);

  // Assign feature is avilable only for people who is > manager role
  const shouldShowAssignButton = () => {
    const requiredRole = UserRoles.manager;
    return hasMinimumRequiredRole({ userRole, requiredRole });
  };

  /// Generates custom column header
  const generatedColumns = columnDefinition.map((data) => {
    data.renderHeader = getHeader;
    switch (data.field) {
      case "hasEscalated":
        data.renderCell = (params: GridRenderCellParams) =>
          VueGridImport.getCustomCell(
            params,
            () => {},
            () => {
              setHoveredVueId(params.id.toString());
            },
            escalationReasonsList
          );
        return data;
      case "vueId":
      case "vueName":
        data.renderCell = (params: GridRenderCellParams) =>
          VueGridImport.getCustomCell(params, () => {
            onClickLinkElement(params);
          });
        return data;
      case "siteName":
        data.renderCell = (params: GridRenderCellParams) =>
          VueGridImport.getCustomCell(
            params,
            () => {
              saveSelectedFiltersAndSearchOnNavigation();
              navigate(`${RoutePath.vues}/site-details`, {
                state: {
                  jobSiteID: params.row.jobSiteId,
                  siteName: params.row.siteNameNumber,
                },
              });
            },
            () => {}, // onMouseOverChip, can be ignored
            [], // reasonsList, can be ignored
            shouldShowAssignButton(),
            () => {
              // On click assign button
              const { row } = params;
              setSelectedVueForSiteName(row);
            }
          );
        return data;
      case "vueTags":
        data.renderCell = (params: GridRenderCellParams) =>
          VueGridImport.getCustomCell(params, (tag) => {
            onSelectVueTag(tag);
          });
        return data;
      case "photoTags":
        data.renderCell = (params: GridRenderCellParams) =>
          VueGridImport.getCustomCell(params, (tag) => {
            // No action required
          });
        return data;
      case "slaActual":
        data.valueFormatter = (params) => {
          const { value } = params;
          if (parseInt(value) < 0) {
            return "-";
          }
          return value;
        };
        return data;
      default:
        return data;
    }
  });

  /// Handles the onClick of "Filter" icon in the column header
  const handleFilterIconClick = (field: string) => {
    // Helps to show the popup. If the field is already showing, setting null will hide the popup
    setPopperName(popperName === field ? null : field);
  };

  /********************* GENERATING THE ROWS ****************/
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      var newPageNumber = isScrolling ? metaValues.pageNumber + 1 : 1;
      return generateGridAPIParamter(
        clientVuesState,
        newPageNumber,
        DEFAULT_PAGE_SIZE
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [clientVuesState, rows, metaValues.pageNumber]
  );

  const fetchVueListFromServer = useCallback(
    async (isScrolling: boolean, refreshVuesForExport: boolean) => {
      setLoader(true);
      if (refreshVuesForExport) {
        setShowCustomIndicator(true);
      }
      const parameters = getParameterString(isScrolling);
      const response = await getClientVues(parameters);
      var gridData: VueGridImport.GridData[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          submissions,
          vueStatusCount,
          meta,
          metaKeys,
        }: {
          submissions: DashboardVue[];
          vueStatusCount: VueStatusCount;
          meta: VuesPageAPIMetaData;
          metaKeys: string[];
        } = response.data;
        gridData = submissions.map((dashboardVue) => {
          return getGridDataFromDashboardVue(dashboardVue);
        });
        setVueStatusCount(vueStatusCount);
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
        // Extracting unique elements from teh keys to avoid issues with data grid
        const uniqueKeys = metaKeys;
        // Updating the custom columns only if there is any changes in the values
        if (!areArraysEqual(metaColumnNames, uniqueKeys)) {
          setMetaColumnNames(uniqueKeys);
        }
        if (initialRender) {
          setOfMetaColumns.current = uniqueKeys;
        }
      } else {
        console.log(response);
      }
      if (mounted.current) {
        setLoader(false);
        if (isScrolling) {
          setRows(rows.concat(gridData));
          setGridData(rows.concat(gridData));
        } else {
          setRows(gridData);
          setGridData(gridData);
        }
      }
      // NOTE: As a temporary solution for generating pdf with latest changes,
      // the vues will be reloaded before the export dialog is displayed.
      // This flow will be altered once feature to send export through email is ready
      if (refreshVuesForExport) {
        setShowCustomIndicator(false);
        setShowExportPopup(true);
      }
      setInitialRender(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        rows.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchVueListFromServer(true, false);
      }
    };

  // Should update the column size, but prevent the frequent API calls
  const handleColumnResize = (params: GridColumnResizeParams) => {
    const { colDef } = params;
    const { headerName, width } = colDef;
    setColumnWidthObject({
      columnName: headerName,
      columnWidth: Math.ceil(width),
    });
  };

  // Handles the column reorder functionality
  const handleColumnReOrder = (params: GridColumnOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;

    var newColumnDefinition = [...columnDefinition];
    // Swapping items to update the database
    const temp = newColumnDefinition[targetIndex - 1];
    newColumnDefinition[targetIndex - 1] = newColumnDefinition[oldIndex - 1];
    newColumnDefinition[oldIndex - 1] = temp;
    setColumnDefinition(newColumnDefinition);
    const newVisibleColumns = newColumnDefinition
      .filter(
        (column) => !column.hideable && !pinnedColumnIds.includes(column.field)
      )
      .map((data) => data.headerName);
    setVisibleColumnsList(newVisibleColumns);
  };

  /********************* CHECK BOX AND ROW SELECTION ****************/

  /// Handles the onChange of check box
  const handleSelectAllCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAllChecked(checked);
    setDidUseSelectAllOption(checked);
    if (!checked) {
      setExcludedVueIds([]);
    }
    const listOfSelectedRowIds: VueGridImport.GridRowId[] = rows.map(
      (row) => row.id
    );
    setSelectedRowsList(checked ? listOfSelectedRowIds : []);
  };

  /// Handles the change in rows selection
  const handleRowSelectionChange = (
    newRowIds: VueGridImport.GridRowSelectionModel
  ) => {
    setSelectedRowsList(newRowIds);
    const newSelectAllValue = newRowIds.length === rows.length;
    setSelectAllChecked(newSelectAllValue);
    if (didUseSelectAllOption && !newSelectAllValue) {
      // excluded vues are needed only when the user previously selected all items
      // and started deselecting by clicking any of the rows.
      const deselectedRowIds = rows
        .filter((row) => !newRowIds.includes(row.id))
        .map((item) => item.id);
      const excludedUniqueIds = Array.from(new Set(deselectedRowIds));
      setExcludedVueIds(excludedUniqueIds);
    } else {
      setExcludedVueIds([]);
    }
  };

  /********* ACTION BAR ****************/

  /// Handles the onClick of "Add Column" button
  const handleManageColumnButtonClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorElement(event.currentTarget);
    // Hard coded field name for add new column
    const field = "addNewColumn";
    // Helps to hide the popup
    setPopperName(popperName === field ? null : field);
  };

  /// Handles the click on "Action Bars"
  const handleActionBarPopupVisibility = (
    type: VueGridImport.ActionButtonType,
    visible: boolean
  ) => {
    switch (type) {
      case VueGridImport.ActionButtonType.export:
        if (visible) {
          // NOTE: As a temporary solution for generating pdf with latest changes,
          // the vues will be reloaded before the export dialog is displayed.
          // This flow will be altered once feature to send export through email is ready
          fetchVueListFromServer(false, true);
        } else {
          setShowExportPopup(visible);
        }
        break;
      case VueGridImport.ActionButtonType.makeUrgent:
        setShowMakeUrgentPopup(visible);
        break;
      case VueGridImport.ActionButtonType.reissue:
        setShowReIssuePopup(visible);
        break;
      case VueGridImport.ActionButtonType.cancel:
        setShowCancelPopup(visible);
        break;
      default:
        break;
    }
  };

  /***********************Reissue Popup*************************/

  const handleReissueClick = async () => {
    const selectedIds = selectedRowsList.map((value) => value.toString());

    if (selectedIds.length <= 0) {
      // No rows selected and hiding the dialog
      onCloseReissueDialog();
      return;
    }
    const response = await reissueVues(selectedIds);
    setLoader(true);
    if (response.status === WebServiceStatus.success) {
      const batchesList = response.data.batches as BatchItem[];
      if (batchesList.length !== 0) {
        setSnackbarContent({
          title: "Vue Reissued",
          message: "Vue reissued successfully.",
          isError: false,
        });
        fetchVueListFromServer(false, false);
      } else {
        setSnackbarContent({
          title: "Vue Reissue Failed",
          message: "Vues cannot be reissued.",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
    }
    setLoader(false);
    onCloseReissueDialog();
  };

  const onCloseReissueDialog = () => {
    handleActionBarPopupVisibility(
      VueGridImport.ActionButtonType.reissue,
      false
    );
  };

  const cancelVue = (confirmCancel: boolean) => {
    const selectedIds = selectedRowsList.map((value) => value.toString()) ?? [];
    const snackBarText = selectedIds.length === 1 ? "Vue" : "Vues";
    handleActionBarPopupVisibility(
      VueGridImport.ActionButtonType.cancel,
      false
    );
    if (confirmCancel) {
      fetchVueListFromServer(false, false);
      setSnackbarContent({
        title: `${snackBarText} Cancelled`,
        message: `${snackBarText} cancelled successfully.`,
        isError: false,
      });
    }
  };

  /************** Make Urgent Popup ****************/

  const onCloseMakeUrgentDialog = () => {
    handleActionBarPopupVisibility(
      VueGridImport.ActionButtonType.makeUrgent,
      false
    );
  };

  const onMakeUrgentButtonClick = async () => {
    const selectedIds = selectedRowsList.map((value) => value.toString());

    if (selectedIds.length <= 0) {
      // No rows selected and hiding the dialog
      onCloseMakeUrgentDialog();
      return;
    }
    const response = await setVuesAsUrgent(selectedIds);
    if (response.status === WebServiceStatus.success) {
      onCloseMakeUrgentDialog();
      fetchVueListFromServer(false, false);
      setSnackbarContent({
        title: "Make Urgent",
        message: "Vue marked as Urgent successfully.",
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error ?? GENERIC_ERROR_MESSAGE,
        isError: true,
      });
      onCloseMakeUrgentDialog();
    }
  };

  /************** POPPERS ****************/

  /// Remove column logic
  const handleRemoveColumn = (type: string) => {
    var newColumnModel = columnVisibilityModel;
    columnDefinition
      .filter((item) => item.field === type)
      .forEach((data: VueGridImport.GridColDef) => {
        newColumnModel[data.field] = false;
        data.hideable = true;
      });
    const newVisibleColumnsList = columnDefinition
      .filter((column) => !column.hideable)
      .map((item) => item.headerName);
    setVisibleColumnsList(newVisibleColumnsList);
    setColumnVisibilityModel(newColumnModel);
  };

  const getListStyledPopper = () => {
    var children: VueGridImport.ReactNode = <></>;
    if (VueGridImport.listStyledPoppers.includes(popperName)) {
      switch (popperName) {
        case "addNewColumn":
          children = getManageColumnPopperContent();
          break;
        default:
          // Filtering data and sending it dynamically
          children = getListStyledPopperContent();
          break;
      }

      return (
        <VueGridImport.CustomPopper
          onClickAway={(event: MouseEvent | TouchEvent) => {
            handleClosePopper(event);
          }}
          shouldOpen={true}
          anchorElement={anchorElement}
          id={popperName}
          placement={popperName === "addNewColumn" ? "bottom" : "right"}
        >
          {children}
        </VueGridImport.CustomPopper>
      );
    } else {
      return <></>;
    }
  };

  const getListStyledPopperContent = () => {
    var popperContent;
    let previouslySelectedItems: string[] = [];
    switch (popperName) {
      case "vueTags":
        const vueTagsIncludingAllOption = ["ALL", ...allVueTagNames.sort()];
        popperContent = vueTagsIncludingAllOption;
        var selectedVueTags = clientVuesState.vueTags;
        if (allVueTagNames.length === selectedVueTags.length) {
          selectedVueTags = vueTagsIncludingAllOption;
        }
        previouslySelectedItems = selectedVueTags;
        break;
      case "photoTags":
        const mediaTagsIncludingAllOption = ["ALL", ...allMediaTagNames.sort()];
        popperContent = mediaTagsIncludingAllOption;
        var selectedMediaTags = clientVuesState.mediaTags;
        if (allMediaTagNames.length === selectedMediaTags.length) {
          selectedMediaTags = mediaTagsIncludingAllOption;
        }
        previouslySelectedItems = selectedMediaTags;
        break;
      case "hasEscalated":
        // Escalated array has boolean value. So converting it to corresponding string values
        popperContent = ["Yes", "No"];
        break;
      case "surveyType":
        popperContent = getSurveyTypeFilterOptions().sort();
        previouslySelectedItems = clientVuesState.types.map((typeId) => {
          return getSurveyTypeStringValue(typeId);
        });
        break;
      case "status":
        popperContent = getVueStatusFilterOptions().sort();
        previouslySelectedItems = clientVuesState.statuses.map((typeId) => {
          return getStatusStringValue(typeId);
        });
        break;
      case "clientName":
        popperContent = availableCompanyList
          .map((company) => company.name)
          .sort();
        previouslySelectedItems = availableCompanyList
          .filter((company) => clientVuesState.companyIds.includes(company.id))
          .map((company) => company.name);
        break;
      case "country":
        const availableCountryList = ["US", "CA"];
        popperContent = availableCountryList;
        previouslySelectedItems = availableCountryList.filter((country) =>
          clientVuesState.countryAbbreviation.includes(country)
        );
        break;
      default:
        popperContent = [...new Set(rows.map((row) => row[popperName]))].sort();
        break;
    }
    const headerName: string = columnDefinition.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    const shouldShowRemoveColumnButton =
      !pinnedColumnHeaders.includes(columnName);
    const previousSelection =
      clientVuesState.escalated === null
        ? ""
        : clientVuesState.escalated
        ? "Yes"
        : "No";
    return popperName === "hasEscalated" ? (
      <SingleSelectionListFilter
        selectionList={popperContent}
        removeColumn={() => {
          handleRemoveColumn(popperName);
        }}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onSelectionChange={onListStyledFilterChange}
        shouldShowRemoveColumnButton={shouldShowRemoveColumnButton}
        previousSelectedOption={previousSelection}
      />
    ) : (
      <VueGridImport.CustomFilterContent
        customSelectorList={popperContent}
        removeColumn={() => {
          handleRemoveColumn(popperName);
        }}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onFilterChange={onListStyledFilterChange}
        shouldShowRemoveColumnButton={shouldShowRemoveColumnButton}
        previouslySelectedOptions={previouslySelectedItems}
      />
    );
  };

  const onListStyledFilterChange = (newFilters: string[]) => {
    var newlySelectedFilters = [...newFilters];
    if (popperName === "vueTags" || popperName === "photoTags") {
      newlySelectedFilters = newFilters.filter(
        (value) => value.toLowerCase() !== "all"
      );
    }
    const filterObject = {
      headerName: popperName,
      filters: newlySelectedFilters,
    };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.headerName === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (newlySelectedFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  /// Returns the content for add column popup
  const getManageColumnPopperContent = () => {
    return (
      <VueGridImport.ManageColumnContent
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        manageColumn={(newColumns) => {
          const currentColumnDefinition = [...columnDefinition];
          const newlyAddedColumns = newColumns.filter(
            (column) => !visibleColumnsList.includes(column)
          );
          currentColumnDefinition.sort((a, b) => {
            return (
              newlyAddedColumns.indexOf(a.headerName) -
              newlyAddedColumns.indexOf(b.headerName)
            );
          });
          setColumnDefinition(currentColumnDefinition);
          setVisibleColumnsList(newColumns);
        }}
        availableOptions={editableColumnNames}
        previouslySelectedItems={visibleColumnsList}
      />
    );
  };

  const updateColumnVisibilityModel = useCallback(() => {
    const visibleColumns = columnDefinition.filter((item) =>
      editableColumnNames.includes(item.headerName)
    );
    var newColumnModel = { ...columnVisibilityModel };

    visibleColumns.map((column) => {
      const shouldDisplayColumn = visibleColumnsList.includes(
        column.headerName
      );
      newColumnModel[column.field] = shouldDisplayColumn;
      column.hideable = !shouldDisplayColumn;
      setColumnVisibilityModel(newColumnModel);
      return column;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumnsList]);

  ///// DATE PICKER STYLED POPPER /////

  const getDatePickerStyledPopper = () => {
    if (VueGridImport.datePickerStyledPoppers.includes(popperName)) {
      const headerName: string = columnDefinition.filter(
        (data) => data.field === popperName
      )[0].headerName;
      const columnName = headerName ?? "";
      const isCreatedDateColumn = headerName === "Created Date";
      const selectedStartDate = isCreatedDateColumn
        ? clientVuesState.createdAtStartDate
        : clientVuesState.completedAtStartDate;
      const selectedEndDate = isCreatedDateColumn
        ? clientVuesState.createdAtEndDate
        : clientVuesState.completedAtEndDate;
      const dateFormat = "MM / DD / YYYY";
      return (
        <VueGridImport.CustomPopper
          onClickAway={(event: MouseEvent | TouchEvent) => {
            handleClosePopper(event);
          }}
          shouldOpen={true}
          anchorElement={anchorElement}
          id={popperName}
          placement="right"
          children={
            <VueGridImport.FilterByDateContent
              removeColumn={() => {
                handleRemoveColumn(popperName);
              }}
              applyDateFilter={(startDate, endDate) => {
                handleDateFilterChange(popperName, startDate, endDate);
              }}
              columnName={columnName}
              closePopper={(
                event: Event | React.SyntheticEvent<Element, Event>
              ) => {
                handleClosePopper(event);
              }}
              selectedStartDate={selectedStartDate.formatUTCNanoSecondsToString(
                dateFormat
              )}
              selectedEndDate={selectedEndDate.formatUTCNanoSecondsToString(
                dateFormat
              )}
              shouldShowRemoveColumnButton={true}
            />
          }
        />
      );
    } else {
      return <></>;
    }
  };

  ///// TIME PICKER STYLED POPPER /////

  const getTimePickerStyledPopper = () => {
    if (timePickerStyledPoppers.includes(popperName)) {
      const headerName: string = columnDefinition.filter(
        (data) => data.field === popperName
      )[0].headerName;
      const columnName = headerName ?? "";
      const isCreatedTimeColumn = headerName === "Created Time";
      const selectedStartTime = isCreatedTimeColumn
        ? clientVuesState.createdAtStartTime
        : clientVuesState.completedAtStartTime;
      const selectedEndTime = isCreatedTimeColumn
        ? clientVuesState.createdAtEndTime
        : clientVuesState.completedAtEndTime;
      return (
        <VueGridImport.CustomPopper
          onClickAway={(event: MouseEvent | TouchEvent) => {
            handleClosePopper(event);
          }}
          shouldOpen={true}
          anchorElement={anchorElement}
          id={popperName}
          placement="right"
          children={
            <FilterByTimeContent
              removeColumn={() => {
                handleRemoveColumn(popperName);
              }}
              applyTimeFilter={(startTime, endTime) => {
                handleTimeFilterChange(popperName, startTime, endTime);
              }}
              columnName={columnName}
              closePopper={(
                event: Event | React.SyntheticEvent<Element, Event>
              ) => {
                handleClosePopper(event);
              }}
              selectedStartTime={selectedStartTime}
              selectedEndTime={selectedEndTime}
            />
          }
        />
      );
    } else {
      return <></>;
    }
  };

  const getPopperWithTextfield = () => {
    if (
      textFieldStyledPoppers.includes(popperName) ||
      setOfMetaColumns.current.includes(popperName)
    ) {
      const headerName: string = columnDefinition.filter(
        (data) => data.field === popperName
      )[0].headerName;
      const columnName = headerName ?? "";
      const shouldShowRemoveColumnButton =
        !pinnedColumnHeaders.includes(columnName);
      return (
        <VueGridImport.CustomPopper
          onClickAway={(event: MouseEvent | TouchEvent) => {
            handleClosePopper(event);
          }}
          shouldOpen={true}
          anchorElement={anchorElement}
          id={popperName}
          placement={"right"}
        >
          <FilterByTextContent
            removeColumn={() => {
              handleRemoveColumn(popperName);
            }}
            columnName={columnName}
            closePopper={(
              event: Event | React.SyntheticEvent<Element, Event>
            ) => {
              handleClosePopper(event);
            }}
            onTextChange={(text) => {
              const filterObject = {
                headerName: popperName,
                filters: [text],
              };
              const indexOfItem = selectedFilters.findIndex(
                (item) => item.headerName === popperName
              );
              var updatedFilters = [...selectedFilters];
              if (text.isEmpty()) {
                // Filters on this columns has been removed
                if (indexOfItem !== -1) {
                  // Item already available. Removing the item
                  updatedFilters.splice(indexOfItem, 1);
                }
              } else {
                if (indexOfItem !== -1) {
                  updatedFilters[indexOfItem] = filterObject;
                } else {
                  updatedFilters.push(filterObject);
                }
              }
              setSelectedFilters(updatedFilters);
            }}
            shouldShowRemoveColumnButton={shouldShowRemoveColumnButton}
            searchedText={getPreviouslySearchedText(
              clientVuesState,
              popperName
            )}
          />
        </VueGridImport.CustomPopper>
      );
    } else {
      return <></>;
    }
  };

  const getRangeStyledPopper = () => {
    if (rangeStyledPoppers.includes(popperName)) {
      const headerName: string = columnDefinition.filter(
        (data) => data.field === popperName
      )[0].headerName;
      const columnName = headerName ?? "";
      const isSLATargetColumn = headerName === "SLA Target";
      const minRange = isSLATargetColumn
        ? clientVuesState.slaTargetMin
        : clientVuesState.slaActualMin;
      const maxRange = isSLATargetColumn
        ? clientVuesState.slaTargetMax
        : clientVuesState.slaActualMax;
      return (
        <VueGridImport.CustomPopper
          onClickAway={(event: MouseEvent | TouchEvent) => {
            handleClosePopper(event);
          }}
          shouldOpen={true}
          anchorElement={anchorElement}
          id={popperName}
          placement="right"
          children={
            <FilterByRangeContent
              shouldShowRemoveColumnButton={true}
              removeColumn={() => {
                handleRemoveColumn(popperName);
              }}
              applyRangeFilter={(minRange, maxRange) => {
                handleRangeBasedFilterChange(minRange, maxRange);
              }}
              columnName={columnName}
              closePopper={(
                event: Event | React.SyntheticEvent<Element, Event>
              ) => {
                handleClosePopper(event);
              }}
              previousMinRange={minRange}
              previousMaxRange={maxRange}
            />
          }
        />
      );
    } else {
      return <></>;
    }
  };

  const handleRangeBasedFilterChange = (minRange: string, maxRange: string) => {
    const filterObject = {
      headerName: popperName,
      filters: [minRange, maxRange],
    };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.headerName === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (minRange.isEmpty() && maxRange.isEmpty()) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  /// Handles the close of popper component
  const handleClosePopper = (event: Event | React.SyntheticEvent) => {
    // Helps to hide the popups
    setPopperName(null);
  };

  // Sets the sort column values
  const setSortColumn = (name: string, isAscendingOrder: boolean) => {
    dispatchClientsVueFetchAction({
      type: VuesPageActionType.sortColumnChanged,
      payload: {
        ...clientVuesState,
        sortColumnDisplayName: name,
        sortIsAscending: isAscendingOrder,
      },
    });
  };

  /// Handles the date filter change
  const handleDateFilterChange = (
    fieldName: string,
    startDate: Moment | null,
    endDate: Moment | null
  ) => {
    var formattedStartDate = "";
    var formattedEndDate = "";
    var appliedFilters: string[] = [formattedStartDate, formattedEndDate];
    const isValidStartDate = startDate !== null && startDate.isValid();
    const isValidEndDate = endDate !== null && endDate.isValid();
    if (!isValidStartDate && !isValidEndDate) {
      updateDateTimeBasedFilter(fieldName, []);
      return;
    }
    if (isValidStartDate) {
      formattedStartDate = filterDateToNanoSecondsString(startDate);
      appliedFilters[0] = formattedStartDate;
    }

    if (isValidEndDate) {
      formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      appliedFilters[1] = formattedEndDate;
    }
    // Adding the date picker to the filter column
    updateDateTimeBasedFilter(fieldName, appliedFilters);
  };

  const handleTimeFilterChange = (
    fieldName: string,
    startTime: Moment | null,
    endTime: Moment | null
  ) => {
    var formattedStartTime = "";
    var formattedEndTime = "";
    var appliedFilters: string[] = [formattedStartTime, formattedEndTime];
    const isValidStartTime = startTime !== null && startTime.isValid();
    const isValidEndTime = endTime !== null && endTime.isValid();
    if (!isValidStartTime && !isValidEndTime) {
      updateDateTimeBasedFilter(fieldName, []);
      return;
    }
    if (isValidStartTime) {
      formattedStartTime = startTime.format("HH:mm").concat(` ${userTimeZone}`);
      appliedFilters[0] = formattedStartTime;
    }
    if (isValidEndTime) {
      formattedEndTime = endTime.format("HH:mm").concat(` ${userTimeZone}`);
      appliedFilters[1] = formattedEndTime;
    }
    // Adding the date picker to the filter column
    updateDateTimeBasedFilter(fieldName, appliedFilters);
  };

  // Updates the filter list with new set of filter, selected by date/time popup
  const updateDateTimeBasedFilter = (
    fieldName: string,
    newFilters: string[]
  ) => {
    const filterObject = {
      headerName: fieldName,
      filters: newFilters,
    };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.headerName === fieldName
    );
    var updatedFilters = [...selectedFilters];
    if (newFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
  };

  // Handles the clear filter button
  const onClearAllFilterButtonClick = () => {
    setSelectedFilters([]);
    setExcludedVueIds([]);
    setSortModel([]);
  };

  useEffect(() => {
    if (!selectAllChecked && !didUseSelectAllOption) return;
    const selectedRowIds: GridRowId[] = rows
      .filter((row) => !excludedVueIds.includes(row.id))
      .map((row) => row.id);
    setSelectedRowsList(didUseSelectAllOption ? selectedRowIds : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rows]);

  // Fetching the Vue tag names on page load
  useEffect(() => {
    const fetchAllVueTagNames = async () => {
      setLoader(true);
      const companyIds = availableCompanyList.map((company) => company.id);
      if (companyIds.length <= 0) {
        return;
      }
      const response = await getAllVueTagNameToFilter(companyIds);
      if (response.status === WebServiceStatus.success) {
        setLoader(false);
        const { tags }: { tags: string[] } = response.data;
        const vueTagsUpdated: string[] = [...tags, ...immutableVueTagList];
        setAllVueTagNames(vueTagsUpdated);
      } else {
        setLoader(false);
      }
    };

    fetchAllVueTagNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Fetching the Photo tag names on page load
  useEffect(() => {
    const fetchAllPhotoTagNames = async () => {
      setLoader(true);
      const companyIds = availableCompanyList.map((company) => company.id);
      if (companyIds.length <= 0) {
        return;
      }
      const response = await getAllMediaTagNameToFilter(companyIds);
      if (response.status === WebServiceStatus.success) {
        setLoader(false);
        const { tags }: { tags: string[] } = response.data;
        setAllMediaTagNames(tags);
      } else {
        setLoader(false);
      }
    };

    fetchAllPhotoTagNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handling the text search for the grid
  useEffect(() => {
    const handleSearchTextChange = (searchText: string) => {
      dispatchClientsVueFetchAction({
        type: VuesPageActionType.searchTextChanged,
        payload: {
          ...clientVuesState,
          search: searchText.trim(),
        },
      });
    };
    /// The method delays the api call for 1 second
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleSearchTextChange(searchInput);
    }, TEXTFIELD_CHANGE_DELAY);

    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchInput]);

  // Handling the grid change on state changes, like filter & sort
  useEffect(() => {
    fetchVueListFromServer(false, false);
    return () => {
      mounted.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientVuesState]);

  // Handles the column width changes
  useEffect(() => {
    const handleColumnWidthChange = async (data: {
      columnName: string;
      columnWidth: number;
    }) => {
      if (data?.columnName !== undefined && data?.columnWidth !== undefined) {
        const response = await setColumnWidthForGrid(
          DataGridType.vues,
          data.columnName,
          data.columnWidth
        );
        if (response.status === WebServiceStatus.success) {
          const currentColumnDefinition = columnDefinition.map((column) => {
            const headerName = column.headerName ?? "";
            if (data.columnName === headerName) {
              column.width = data.columnWidth;
            }
            return column;
          });
          setColumnDefinition(currentColumnDefinition);
          setColumnWidthObject(undefined);
        }
      }
    };
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleColumnWidthChange(columnWidthObject);
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnWidthObject]);

  useEffect(() => {
    const setVueGridPreferences = async () => {
      if (visibleColumnsList === undefined) {
        return;
      }
      await setColumnOrderAndVisibilityForGrid(
        DataGridType.vues,
        visibleColumnsList
      );
      updateColumnVisibilityModel();
    };
    setVueGridPreferences();
  }, [updateColumnVisibilityModel, visibleColumnsList]);

  // Handles the column width changes
  useEffect(() => {
    const fetchMyVuesPreferences = async () => {
      const response = await getPreferencesForGrid(DataGridType.vues);
      if (response.status === WebServiceStatus.success) {
        const { columnPixelWidths, orderedVisibleColumnNames } = response.data;
        const currentColumnDefinition = columnDefinition.map((column) => {
          const headerName = column.headerName ?? "";
          if (columnPixelWidths.hasOwnProperty(headerName)) {
            column.width = columnPixelWidths[headerName];
          }
          return column;
        });
        currentColumnDefinition.sort((a, b) => {
          return (
            orderedVisibleColumnNames.indexOf(a.headerName) -
            orderedVisibleColumnNames.indexOf(b.headerName)
          );
        });
        setColumnDefinition(currentColumnDefinition);
        setVisibleColumnsList(orderedVisibleColumnNames);
      }
    };
    fetchMyVuesPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handles teh meta column generation, data management and it's visibility
  useEffect(() => {
    // Ensuring the meta column does not exists in the current column definition
    const uniqueMetaColumns = metaColumnNames.filter(
      (columnName) =>
        columnDefinition.findIndex(
          (column) =>
            column.headerName.toLowerCase() === columnName.toLowerCase()
        ) === -1
    );
    // Generating column definition with meta keys
    const metaColumnDefinition = uniqueMetaColumns.map((title) =>
      getCustomColumnDefinitionForField(title)
    );
    // Updating the column definition
    setColumnDefinition([...columnDefinition, ...metaColumnDefinition]);

    if (!visibleColumnsList) {
      // Ignoring preference settings when visible columns are not available
      return;
    }
    // Updating the visible column list
    const visibleColumns = [...visibleColumnsList];
    // Ensuring to display columns that were prefferred by the user
    const newVisibleColumns = uniqueMetaColumns.filter((item) =>
      visibleColumns.includes(item)
    );
    // Updating the column visibility
    setVisibleColumnsList([...visibleColumns, ...newVisibleColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaColumnNames]);

  const getSelectAllLabelText = (): string => {
    return selectedRowsList.length > 0
      ? `${
          selectAllChecked
            ? vueStatusCount.totalVues
            : didUseSelectAllOption && excludedVueIds
            ? vueStatusCount.totalVues - excludedVueIds.length
            : selectedRowsList.length
        }/${vueStatusCount.totalVues}`
      : "";
  };

  const getNoRowsMessage = () => {
    return (
      <VueGridImport.MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          No rows available
        </Typography>
      </VueGridImport.MDBox>
    );
  };

  return (
    <VueGridImport.DashboardContentLayout needCardBackground={false}>
      {showCustomIndicator && <CustomIndicator />}
      {selectedVueForSiteName && getAssignSiteNameDialog()}
      {showMakeUrgentPopup &&
        getMakeUrgentDialog(
          "Are you sure you want to mark these Vue(s) as urgent? Doing so will increase the price of the Vue.",
          showMakeUrgentPopup,
          onCloseMakeUrgentDialog,
          onMakeUrgentButtonClick
        )}
      {showReIssuePopup &&
        getReIssueDialog(
          showReIssuePopup,
          onCloseReissueDialog,
          selectedRowsList,
          handleReissueClick
        )}
      {showCancelPopup &&
        getCancelDialog(showCancelPopup, cancelVue, selectedRowsList)}
      {showExportPopup &&
        getExportDialog(
          excludedVueIds,
          rows,
          didUseSelectAllOption ? [] : selectedRowsList,
          showExportPopup,
          selectAllChecked || didUseSelectAllOption,
          clientVuesState,
          () => {
            handleActionBarPopupVisibility(
              VueGridImport.ActionButtonType.export,
              false
            );
          }
        )}
      {textFieldStyledPoppers.includes(popperName) && getPopperWithTextfield()}
      {setOfMetaColumns.current.includes(popperName) &&
        getPopperWithTextfield()}
      {rangeStyledPoppers.includes(popperName) && getRangeStyledPopper()}
      {VueGridImport.listStyledPoppers.includes(popperName) &&
        getListStyledPopper()}
      {timePickerStyledPoppers.includes(popperName) &&
        getTimePickerStyledPopper()}
      {VueGridImport.datePickerStyledPoppers.includes(popperName) &&
        getDatePickerStyledPopper()}
      <VueGridImport.MDBox
        display="flex"
        alignItems="start"
        pl={2}
        pb={1.4}
        pt={0.6}
      >
        {/* Checkbox with label  */}
        <VueGridImport.FormGroup>
          <VueGridImport.FormControlLabel
            control={
              <VueGridImport.Checkbox
                checked={
                  selectAllChecked ||
                  (didUseSelectAllOption && excludedVueIds.length === 0)
                }
                onChange={handleSelectAllCheckboxChange}
              />
            }
            sx={checkBoxStyle}
            label={getSelectAllLabelText()}
          />
        </VueGridImport.FormGroup>
        {/* Action bar/header container and Search text field  */}
        {selectedRowsList.length > 0 ? (
          <VueGridImport.VueActionBar
            onButtonClick={(type) => {
              handleActionBarPopupVisibility(type, true);
            }}
            shouldDisableCancelButton={shouldDisableCancelButton}
            shouldDisableReissueButton={shouldDisableReissueButton}
            shouldDisableMakeUrgentButton={shouldDisableMakeUrgentButton}
            containsReadOnlyVues={hasReadOnlyVues}
          />
        ) : (
          <VueGridImport.TextField
            fullWidth
            value={searchInput}
            placeholder="Search by Vue ID, Vue Name & Site Name"
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setSearchInput(event.target.value)}
            sx={{ ...searchBarStyle, position: "relative", zIndex: "128" }}
            InputProps={{
              endAdornment: (
                <VueGridImport.InputAdornment position="end">
                  <VueGridImport.Search
                    fontSize="medium"
                    sx={{ color: "#344767" }}
                  />
                </VueGridImport.InputAdornment>
              ),
            }}
          />
        )}
        {/* Clear filter button */}
        <VueGridImport.MDBox ml="auto" display="flex">
          {selectedFilters.length > 0 && (
            <VueGridImport.MDBox pr={2}>
              <VueGridImport.StyledButtonWithIcon
                iconPath={VueGridImport.mdiFilterOff}
                onClick={onClearAllFilterButtonClick}
              >
                {`Clear All Filters (${selectedFilters.length})`}
              </VueGridImport.StyledButtonWithIcon>
            </VueGridImport.MDBox>
          )}
          {/* Manage Column button */}
          <VueGridImport.MDButton
            variant="contained"
            startIcon={
              <VueGridImport.Icon
                path={VueGridImport.mdiPlusMinusVariant}
                size={0.8}
              />
            }
            endIcon={
              <VueGridImport.Icon
                path={VueGridImport.mdiChevronDown}
                size={0.8}
              />
            }
            color="success"
            disabled={rows.length === 0}
            onClick={handleManageColumnButtonClick}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            COLUMN
          </VueGridImport.MDButton>
        </VueGridImport.MDBox>
      </VueGridImport.MDBox>
      <VueGridImport.Card
        sx={{
          minHeight: `calc(100vh - 156px)`,
          height: `calc(100vh - 156px)`,
          justifyContent: "flex-start",
          position: "relative",
        }}
      >
        {/* Default Column Section */}
        <VueGridImport.DataGridPremium
          apiRef={gridRef}
          rows={rows}
          columns={generatedColumns}
          checkboxSelection
          disableColumnMenu
          disableColumnSorting
          onRowSelectionModelChange={handleRowSelectionChange}
          rowSelectionModel={selectedRowsList}
          columnVisibilityModel={columnVisibilityModel}
          sortModel={sortModel}
          sx={gridStyle}
          sortingMode="server"
          filterMode="server"
          loading={loader}
          onRowsScrollEnd={handleOnRowsScrollEnd}
          onColumnResize={handleColumnResize}
          onColumnOrderChange={handleColumnReOrder}
          keepNonExistentRowsSelected
          slots={{
            footer: getFooter,
            noRowsOverlay: getNoRowsMessage,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          initialState={{
            density: "compact",
            pinnedColumns: {
              left: [
                VueGridImport.GRID_CHECKBOX_SELECTION_COL_DEF.field,
                ...pinnedColumnIds,
              ],
            },
          }}
        />
        <CustomSnackbar
          snackbarContent={snackbarContent}
          onClose={() => {
            setSnackbarContent(null);
          }}
        />
      </VueGridImport.Card>
      <ScrollToTopButton
        iconPath={VueGridImport.mdiArrowUp}
        handleOnClick={() => {
          scrollToTop(gridRef);
        }}
        sx={{ display: "none" }}
      />
      <Outlet />
    </VueGridImport.DashboardContentLayout>
  );
};

export default Vues;
