import {
  CellEditIconType,
  EditableCell,
  checkBoxStyle,
  searchBarStyle,
  inputEditStyles,
  textEllipsis,
  gridStyle,
  cellEditIconsStyle,
  HeaderSortIconButton,
  hasBasicData,
  TEXTFIELD_CHANGE_DELAY,
  UpdateAddressDialogContent,
  LatLng,
  SiteAddressProp,
  linkElementStyle,
  CustomDropdown,
  styleForUploadTemplateGrid,
  downloadFileToDevice,
  getFieldNameFromHeader,
  CustomDropdownOption,
  AddressValidationResult,
  CustomDialogBox,
} from "@ivueit/vue-engine";
import {
  mdiCursorMove,
  mdiFilter,
  mdiFilterOff,
  mdiMapMarker,
  mdiPencil,
} from "@mdi/js";
import Icon from "@mdi/react";
import { Search } from "@mui/icons-material";
import {
  Card,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  DataGridPremiumProps,
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridCellEditStartParams,
  GridColDef,
  GridColumnHeaderParams,
  GridColumnOrderChangeParams,
  GridColumnResizeParams,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowClassNameParams,
  GridRowId,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  MuiEvent,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import {
  SiteListActionBar,
  SiteListActionBarButtonType,
  StyledButtonWithIcon,
} from "./SiteListActionBar";
import SiteListTopBar, { SiteListTopBarButtonType } from "./SiteListTopBar";
import { BulkImportDialogContent } from "./BulkImportDialogContent";
import { AddSiteState, ShowConfirmation } from "../helpers/constants";
import { AddSiteDialogContent } from "./AddSiteDialogContent";
import { dataGridCellButtonStyle } from "../styles/site_list_style";
import { ConfirmationDialog } from "./ConfirmationDialog";
import {
  defaultColumnData,
  generateSiteListAPIParamter,
  getGridDataForSiteList,
  getPayloadForTextFieldChangesForSiteList,
  getPreviouslySearchedTextForSiteList,
  getIndexOfRowWithDuplicateSiteName as getRowIndexWithDuplicateSiteName,
  isContainEmptyAddressComponent,
  knownEditableColumns,
  knownSitelistColumns,
  siteListPinnedColumns,
} from "../helpers/helpers";
import { CustomPopper } from "../../vues/vue-grid/components/CustomPopper";
import SingleSelectionListFilter from "../../vues/vue-grid/components/SingleSelectionListFilter";
import {
  CustomFilterContent,
  ManageColumnContent,
} from "../../vues/vue-grid/helpers/imports";
import {
  GridFilter,
  SiteListDataFromServer,
  SiteListGridData,
  SiteListPageAPIMetaData,
} from "../interfaces/interfaces";
import { useNavigate } from "react-router-dom";
import {
  DEFAULT_PAGE_SIZE,
  GENERIC_ERROR_MESSAGE,
  RoutePath,
} from "../../../../../constants";
import SiteListBottomBar from "./BottomBar";
import {
  SiteListPageActionType,
  defaultSiteListPageSummaryState,
  siteListPageSummaryReducer,
} from "../helpers/reducer";
import { defaultMetaData } from "../../vues/vue-grid/types/constants";
import {
  addSiteList,
  bulkImportSiteList,
  bulkArchiveSiteList,
  editSiteList,
  exportSiteList,
  getSiteList,
} from "../services/siteListServices";
import { WebServiceStatus } from "utils/services/AppUrls";
import { CustomIndicator } from "pages/components/CustomIndicator";
import { useAuth } from "context/AuthProvider";
import { UserInfo } from "pages/profile/utils/ProfileInterfaces";
import { downloadFile } from "pages/profile/services/ProfileServices";
import FilterByTextContent from "../../vues/vue-grid/components/FilterByTextContent";
import { alphanumericSort } from "utils/helpers/extensions";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  getPreferencesForGrid,
  setColumnOrderAndVisibilityForGrid,
  setColumnWidthForGrid,
} from "../../common/services/services";
import { DataGridType, GridPreferences } from "../../common/types/types";

// Defines the button types used within the screen
enum EditButtonType {
  edit,
  map,
}

const availableSiteStatus = ["All", "Active", "Archived"];

const IMPORT_QUEUE_LIMIT = 300;

const SiteListDataGrid = () => {
  const { userData }: { userData: UserInfo } = useAuth();
  // Set companyId
  const companyId = userData.companyId;
  const [loader, setLoader] = useState(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  // Defines the meta values for API
  const [metaValues, setMetaValues] =
    useState<SiteListPageAPIMetaData>(defaultMetaData);
  /// Defines the non validated row data
  const [nonValidatedRowData, setNonValidatedRowData] = useState<GridRowsProp>(
    []
  );
  /// Defines the validated row data
  const [validatedRowData, setValidatedRowData] = useState<GridRowsProp>([]);
  /// Defines the validated row data
  const [nonFilteredRowData, setNonFilteredRowData] = useState<GridRowsProp>(
    []
  );
  /// Defines the invalid row data
  const [invalidRowData, setInvalidRowData] = useState<GridRowsProp>([]);
  /// Defines the column data
  const [columnData, setColumnData] = useState<GridColDef[]>(defaultColumnData);
  const [editableColumns, setEditableColumns] =
    useState<EditableCell[]>(knownEditableColumns);
  const [editableHeaders, setEditableHeaders] = useState<EditableCell[]>([]);
  const [allCustomColumns, setAllCustomColumns] = useState<GridColDef[]>([]);
  /// Determines whether to select all cells or not
  const [selectAll, setSelectAll] = useState<boolean>(false);
  // Selecting all rows when the page is loading
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  // defines the search text
  const [searchText, setSearchText] = useState<string>("");
  /// This is to avoid the unwanted useEffect invocation unless if the search text hasn't chnaged
  const [searchTextChanged, setSearchTextChanged] = useState<boolean>(false);
  // Defines the cell param which is in editing
  const [cellInEdit, setCellInEdit] = useState<null | GridRenderCellParams>(
    null
  );
  const setOfGridColumns = useRef<GridColDef[]>([]);
  // Defines the name of field which the user is editing currently. Empty means no editing.
  const [headerInEdit, setHeaderInEdit] = useState<string>("");
  // Defines the value of currently editing header
  const [editedHeader, setEditedHeader] = useState<string>("");
  /// Sort model for the grid
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  /// Defines the anchor element for the popup
  const [anchorElement, setAnchorElement] = useState<
    HTMLButtonElement | HTMLDivElement
  >(null);
  /// Defines the popup fields
  const [popperName, setPopperName] = useState<string>(null);
  /// Holds the list of selected filters
  const [selectedFilters, setSelectedFilters] = useState<GridFilter[]>([]);
  /// State to open the add site dialog
  const [openAddSiteDialog, setOpenAddSiteDialog] = useState(false);
  const [showMapDialog, setShowMapDialog] = useState(false);
  /// State to open the bulk import dialog
  const [openBulkImportDialog, setBulkImportDialog] = useState(false);
  /// Status selected
  const [siteStatus, setSiteStatus] = useState<string>("All");
  const [addNewSiteData, setAddNewSiteData] = useState<any | null>(null);
  const [showConfirmation, setShowConfirmation] = useState<ShowConfirmation>({
    visible: false,
    type: SiteListActionBarButtonType.none,
  });
  const [visibleColumnNames, setVisibleColumnNames] = useState<string[]>();
  const [visibleCustomColumns, setVisibleCustomColumns] = useState<
    GridColDef[]
  >([]);
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>();
  const [snackbarContent, setSnackbarContent] =
    useState<CustomSnackbarContent | null>(null);
  /// Site list reducer
  const [siteListsState, dispatchSiteListFetchAction] = useReducer(
    siteListPageSummaryReducer,
    {
      ...defaultSiteListPageSummaryState,
      companyId: companyId,
      sortColumnDisplayName: "Site Name",
      sortIsAscending: true,
    }
  );
  // Set updated row
  const [updatedRowData, setUpdatedRowData] = useState<any | null>(null);
  const [columnWidthObject, setColumnWidthObject] = useState<{
    columnName: string;
    columnWidth: number;
  }>();
  // hook to perform navigation
  const navigate = useNavigate();
  /// Reference for the grid
  const gridRef = useGridApiRef();

  const updateColumnVisibilityModel = useCallback(() => {
    const editableColumnNames = columnData
      .filter((column) => !siteListPinnedColumns.includes(column.field))
      .map((column) => column.headerName ?? "")
      .sort((a, b) => alphanumericSort(a, b));
    const visibleColumns = columnData.filter((item) =>
      editableColumnNames.includes(item.headerName)
    );
    var newColumnModel = { ...columnVisibilityModel };

    visibleColumns.map((column) => {
      const shouldDisplayColumn = visibleColumnNames.includes(
        column.headerName
      );
      newColumnModel[column.field] = shouldDisplayColumn;
      column.hideable = !shouldDisplayColumn;
      setColumnVisibilityModel(newColumnModel);
      return column;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumnNames]);

  const handleDropDownClick = (newStatus: CustomDropdownOption) => {
    setSiteStatus(newStatus.value);
    var archivedStatus = null as boolean;
    switch (newStatus.value) {
      case "Active":
        archivedStatus = false;
        break;
      case "All":
        archivedStatus = null;
        break;
      case "Archived":
        archivedStatus = true;
        break;
    }
    dispatchSiteListFetchAction({
      type: SiteListPageActionType.listBasedFilterApplied,
      payload: {
        ...siteListsState,
        archive: archivedStatus,
      },
    });
  };

  /// GRID PREFERENCES ////

  // Handles the column width changes
  const fetchSiteListGridPreferences = async () => {
    const response = await getPreferencesForGrid(DataGridType.sites);
    if (response.status === WebServiceStatus.success) {
      const peferences = response.data as GridPreferences;
      const { columnPixelWidths, orderedVisibleColumnNames } = peferences;
      const currentColumnDefinition = columnData.map((column) => {
        const headerName = column.headerName ?? "";
        if (columnPixelWidths.hasOwnProperty(headerName)) {
          column.width = columnPixelWidths[headerName];
        }
        return column;
      });
      currentColumnDefinition.sort((a, b) => {
        return (
          orderedVisibleColumnNames.indexOf(a.headerName) -
          orderedVisibleColumnNames.indexOf(b.headerName)
        );
      });
      setColumnData(currentColumnDefinition);
      setVisibleColumnNames(orderedVisibleColumnNames);
    }
  };

  useEffect(() => {
    fetchSiteListGridPreferences();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metaValues]);

  // Handling the visible columns
  useEffect(() => {
    const setVueGridPreferences = async () => {
      if (!visibleColumnNames) {
        return;
      }
      const response = await setColumnOrderAndVisibilityForGrid(
        DataGridType.sites,
        visibleColumnNames
      );
      if (response.status === WebServiceStatus.success) {
        updateColumnVisibilityModel();
      }
    };
    setVueGridPreferences();
  }, [updateColumnVisibilityModel, visibleColumnNames]);

  useEffect(() => {
    fetchSiteListFromServer(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [siteListsState]);

  // Handles the column width changes
  useEffect(() => {
    const handleColumnWidthChange = async (data: {
      columnName: string;
      columnWidth: number;
    }) => {
      if (data?.columnName !== undefined && data?.columnWidth !== undefined) {
        const response = await setColumnWidthForGrid(
          DataGridType.sites,
          data.columnName,
          data.columnWidth
        );
        if (response.status === WebServiceStatus.success) {
          const currentColumnDefinition = columnData.map((column) => {
            const headerName = column.headerName ?? "";
            if (data.columnName === headerName) {
              column.width = data.columnWidth;
            }
            return column;
          });
          setColumnData(currentColumnDefinition);
          setColumnWidthObject(undefined);
          // fetchSiteListGridPreferences();
        }
      }
    };
    /// It helps us to avoid frequent api calls & state updates, on text change
    const delayAPIInvocation = setTimeout(() => {
      handleColumnWidthChange(columnWidthObject);
    }, TEXTFIELD_CHANGE_DELAY);
    return () => clearTimeout(delayAPIInvocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnWidthObject]);

  useEffect(() => {
    // Filtering columns which are not available in the known column list
    const customColumns = columnData.filter((column) => {
      const { field } = column;
      return !knownSitelistColumns.some(
        (header) => getFieldNameFromHeader(header) === field
      );
    });
    setAllCustomColumns(customColumns);
  }, [columnData]);

  //Generating parameters for API
  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      return generateSiteListAPIParamter(siteListsState);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [siteListsState, validatedRowData, metaValues.pageNumber]
  );
  // Get site list from server
  const fetchSiteListFromServer = useCallback(
    async (isScrolling: boolean) => {
      setLoader(true);
      const parameters = getParameterString(isScrolling);
      const response = await getSiteList(parameters);
      var gridData: SiteListGridData[] = [];
      if (response.status === WebServiceStatus.success) {
        const {
          jobSites,
          meta,
        }: {
          jobSites: SiteListDataFromServer[];
          meta: SiteListPageAPIMetaData;
        } = response.data;
        gridData = jobSites.map((siteFromServer) => {
          return getGridDataForSiteList(siteFromServer);
        });
        updateCustomColumnDefinition(gridData);
        setMetaValues({
          pageSize: Number(meta.pageSize),
          pageNumber: Number(meta.pageNumber),
          totalPages: Number(meta.totalPages),
          totalElements: Number(meta.totalElements),
        });
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: response.error ?? GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
      setLoader(false);
      if (isScrolling) {
        setNonValidatedRowData(nonValidatedRowData.concat(gridData));
      } else {
        setNonValidatedRowData(gridData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  /// To select the rows in all pages when "selectAll" is checked
  useEffect(() => {
    if (!selectAll) {
      return;
    }
    const listOfSelectedRowIds: GridRowId[] = nonValidatedRowData.map(
      (row) => row.id
    );
    setSelectedRows(selectAll ? listOfSelectedRowIds : []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonValidatedRowData]);

  /// Handles the click of the "Action Bar" buttons
  const onActionBarClick = (type: SiteListActionBarButtonType) => {
    switch (type) {
      case SiteListActionBarButtonType.exportSiteList:
        exportSiteFromServer();
        break;
      case SiteListActionBarButtonType.archive:
        setShowConfirmation({
          type: SiteListActionBarButtonType.archive,
          visible: true,
        });
        break;
      case SiteListActionBarButtonType.unarchive:
        setShowConfirmation({
          type: SiteListActionBarButtonType.unarchive,
          visible: true,
        });
        break;
      case SiteListActionBarButtonType.delete:
        setShowConfirmation({
          type: SiteListActionBarButtonType.delete,
          visible: true,
        });
        break;
    }
  };

  const getParametersForSiteExport = () => {
    const selectedIds: string[] = selectedRows.map((item) => `${item}`);
    // Default parameter data
    var params: any = {
      siteIds: selectedIds,
      companyId: companyId,
    };
    if (selectAll && siteListsState) {
      // When selects all items from the list, we ignore the site ids and
      params = { companyId: companyId };

      // Appending searchText
      if (siteListsState.search.isNotEmpty()) {
        params = { ...params, siteNameAndNumber: siteListsState.search };
      }
      // Appending siteNameAndNumber
      if (siteListsState.siteNameNumber.isNotEmpty()) {
        params = {
          ...params,
          siteNameAndNumber: siteListsState.siteNameNumber,
        };
      }
      // Appending site
      if (siteListsState.site.isNotEmpty()) {
        params = { ...params, siteName: siteListsState.site };
      }
      // Appending siteNumber
      if (siteListsState.siteNumber.isNotEmpty()) {
        params = { ...params, siteNumber: siteListsState.siteNumber };
      }
      // Appending city
      if (siteListsState.city.isNotEmpty()) {
        params = { ...params, city: siteListsState.city };
      }
      // Appending state
      if (siteListsState.state.isNotEmpty()) {
        params = { ...params, state: siteListsState.state };
      }
      // Appending postal code
      if (siteListsState.postalCode.isNotEmpty()) {
        params = { ...params, postalCode: siteListsState.postalCode };
      }
      // Appending country
      if (siteListsState.country.length > 0) {
        params = { ...params, country: siteListsState.country };
      }
      // Appending address
      if (siteListsState.address.isNotEmpty()) {
        params = { ...params, address: siteListsState.address };
      }
      // Appending address2
      if (siteListsState.addressTwo.isNotEmpty()) {
        params = { ...params, addressTwo: siteListsState.addressTwo };
      }
      // Appending archive status
      if (
        siteListsState.archive !== null &&
        siteListsState.archive !== undefined
      ) {
        params = { ...params, archived: { value: siteListsState.archive } };
      }
      // Appending sortColumnDisplayName
      if (siteListsState.sortColumnDisplayName.isNotEmpty()) {
        params = {
          ...params,
          sortColumnDisplayName: siteListsState.sortColumnDisplayName,
        };
      }
      // Appending sortColumnDisplayName
      if (
        siteListsState.sortIsAscending !== null &&
        siteListsState.sortIsAscending !== undefined
      ) {
        params = { ...params, sortIsAscending: siteListsState.sortIsAscending };
      }
    }
    return params;
  };

  const exportSiteFromServer = async () => {
    setShowLoader(true);
    const params = getParametersForSiteExport();
    const response = await exportSiteList(params);
    if (response.status === WebServiceStatus.success) {
      const fileID = response.data.fileId;
      if (fileID) {
        const fileData = await downloadFile(fileID);
        const { mimeType, data } = fileData;
        const url = `data:${mimeType};base64,${data}`;
        try {
          await downloadFileToDevice(url, fileData.filename, fileData.mimeType);
        } catch (error) {
          setSnackbarContent({
            title: "Attention!",
            message: "Failed to download the file",
            isError: true,
          });
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "No file found",
          isError: true,
        });
      }
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getActionButtonConfirmationDialog = () => {
    var title = "";
    var message = "";
    var buttonName = "";
    var { type } = showConfirmation;
    switch (type) {
      case SiteListActionBarButtonType.archive:
        title = "Archive Sites";
        message = "Are you sure you want to archive these sites?";
        buttonName = "Archive";
        break;
      case SiteListActionBarButtonType.unarchive:
        title = "Unarchive Sites";
        message = "Are you sure you want to unarchive these sites?";
        buttonName = "Unarchive";
        break;
      case SiteListActionBarButtonType.delete:
        title = "Delete Sites";
        message = "Are you sure you want to delete these sites?";
        buttonName = "Yes, Delete";
        break;
      default:
        break;
    }
    return (
      <CustomDialogBox
        title={title}
        width="450px"
        openDialog={showConfirmation.visible}
      >
        <ConfirmationDialog
          message={message}
          buttonName={buttonName}
          onClose={() => {
            setShowConfirmation({ visible: false, type: type });
          }}
          onClick={() => {
            setShowConfirmation({ visible: false, type: type });
            handleActionClick(type);
          }}
        />
      </CustomDialogBox>
    );
  };

  const updateArchiveStatusToServer = (type: SiteListActionBarButtonType) => {
    var newRowData = [...nonValidatedRowData];
    const jobSiteIds = selectedRows.map((row) => {
      const siteIndex = newRowData.findIndex((siteRow) => {
        return row === siteRow.id;
      });

      return newRowData[siteIndex].id;
    });
    bulkArchiveSiteListToServer(
      userData.companyId,
      type === SiteListActionBarButtonType.archive,
      jobSiteIds
    );
  };

  const handleActionClick = (type: SiteListActionBarButtonType) => {
    var newRowData = [...nonValidatedRowData];
    switch (type) {
      case SiteListActionBarButtonType.archive:
        updateArchiveStatusToServer(type);
        break;
      case SiteListActionBarButtonType.unarchive:
        updateArchiveStatusToServer(type);
        break;
      case SiteListActionBarButtonType.delete:
        newRowData = newRowData.filter((row) => !selectedRows.includes(row.id));
        setNonValidatedRowData(newRowData);
        break;
      default:
        break;
    }
  };

  const onClearAllFilterButtonClick = () => {
    setSelectedFilters([]);
    setValidatedRowData(nonFilteredRowData);
    dispatchSiteListFetchAction({
      type: SiteListPageActionType.clearAllFilterClicked,
      payload: {
        ...defaultSiteListPageSummaryState,
        companyId: companyId,
        search: searchText,
      },
    });
  };

  const generateConstantSetOfColumns = (customColumns: string[]) => {
    const customColumnDefinition: GridColDef[] = customColumns.map(
      (fieldName) => {
        return {
          field: getFieldNameFromHeader(fieldName),
          headerName: fieldName.toUpperCase(),
          width: 200,
          sortable: false,
          editable: true,
        };
      }
    );
    /// This contains the constant set of all the column names
    setOfGridColumns.current = [
      ...defaultColumnData,
      ...customColumnDefinition,
    ];
  };

  const updateCustomColumnDefinition = (newGridData: SiteListGridData[]) => {
    const columns = newGridData
      .map((grid) => {
        // Fetching all field names from the grid object
        const allColumnNames = Object.keys(grid).map((name) =>
          getFieldNameFromHeader(name)
        );
        // Field names which we are expecting
        const knownSitelistFields = knownSitelistColumns.map((header) =>
          getFieldNameFromHeader(header)
        );
        // Filtering unknown fields, expecting to be the custom columns
        return allColumnNames.filter(
          (columnName) => !knownSitelistFields.includes(columnName)
        );
      })
      .flat();
    // Flattening to get the unique column name from the list
    const uniqueCustomColumns = [...new Set(columns)];
    generateConstantSetOfColumns(uniqueCustomColumns);
    // Extracting fields from existing definition
    const existingColumnFields = columnData.map((column) =>
      getFieldNameFromHeader(column.field)
    );
    // Extracting column fields whcih are not avilable in the existing grid definition
    const finalCustomFieldList = uniqueCustomColumns.filter(
      (field) => !existingColumnFields.includes(field)
    );
    const customColumnDefinition: GridColDef[] = finalCustomFieldList.map(
      (fieldName) => {
        return {
          field: getFieldNameFromHeader(fieldName),
          headerName: fieldName.toUpperCase(),
          width: 200,
          sortable: false,
          editable: true,
        };
      }
    );
    // Preparing new column definition
    var updatedColumnData = [...columnData];
    if (finalCustomFieldList.length > 0) {
      updatedColumnData = [...updatedColumnData, ...customColumnDefinition];
    }

    if (visibleColumnNames && visibleColumnNames.length > 0) {
      updatedColumnData = updatedColumnData.filter((column) => {
        if (siteListPinnedColumns.includes(column.field)) {
          return true;
        }
        return visibleColumnNames.some(
          (header) => header.toUpperCase() === column.headerName.toUpperCase()
        );
      });
    }

    // Setting the visible custom columns
    setVisibleCustomColumns(customColumnDefinition);
    // Updating the column data
    setColumnData(updatedColumnData);
    const customColumnList: EditableCell[] = uniqueCustomColumns.map(
      (field) => {
        return { fieldName: getFieldNameFromHeader(field) };
      }
    );
    setEditableColumns([...editableColumns, ...customColumnList]);
    setEditableHeaders([...customColumnList]);
  };

  /////////////////////////////////////// TOP BAR /////////////////////////////////////////////

  /// Handles the click of the "Top Bar" buttons
  const onTopBarClick = (
    type: SiteListTopBarButtonType,
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    switch (type) {
      case SiteListTopBarButtonType.addSite:
        setOpenAddSiteDialog(true);
        break;
      case SiteListTopBarButtonType.bulkImport:
        setBulkImportDialog(true);
        break;
      case SiteListTopBarButtonType.manageColumn:
        setAnchorElement(event.currentTarget);
        const field = "managecolumn";
        setPopperName(popperName === field ? null : field);
        break;
      default:
        break;
    }
  };

  const getBulkImportDialog = () => {
    return (
      <CustomDialogBox
        title="Bulk Import Sites"
        width="1204px"
        height="90vh"
        openDialog={true}
      >
        <BulkImportDialogContent
          onImportSuccess={() => {
            // All requests have been completed successfully
            setSnackbarContent({
              title: "Import Sites!",
              message: "Sites are successfully imported.",
              isError: false,
            });
            setBulkImportDialog(false);
            // Refrehing the screen by reloading all
            fetchSiteListFromServer(false);
          }}
          onCloseDialog={() => {
            setBulkImportDialog(false);
          }}
          originalSiteList={nonFilteredRowData}
        />
      </CustomDialogBox>
    );
  };

  const getMetaFields = (site: any, editableHeader: EditableCell[]) => {
    let customObject: { [key: string]: string } = {};
    editableHeader.forEach((item) => {
      customObject[item.fieldName] = site[item.fieldName];
    });
    return customObject;
  };

  const sliceJobSitesIntoChunks = (sites: any[]) => {
    var chunks: any[] = [];
    for (var index = 0; index < sites.length; index += IMPORT_QUEUE_LIMIT) {
      chunks.push(sites.slice(index, index + IMPORT_QUEUE_LIMIT));
    }
    return chunks;
  };

  const bulkImportSiteListToServer = async (
    companyId: string,
    jobSites: any[]
  ) => {
    const chunksArray = sliceJobSitesIntoChunks(jobSites);
    setShowLoader(true);
    const responses = await Promise.all(
      chunksArray.map(async (sites) => {
        const params = {
          companyId: companyId,
          jobSites: sites,
        };
        return await bulkImportSiteList(params);
      })
    );

    const failedResponses = responses.filter(
      (response) => response.status !== WebServiceStatus.success
    );
    if (failedResponses.length === 0) {
      // All requests have been completed successfully
      fetchSiteListFromServer(false);
      setSnackbarContent({
        title: "Import Sites!",
        message: "Sites are successfully imported.",
        isError: false,
      });
    } else {
      // Atleast one of the request set is failed
      const errorMessage = failedResponses[0].error ?? GENERIC_ERROR_MESSAGE;
      setSnackbarContent({
        title: "Import failed for some sites",
        message: errorMessage,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const bulkArchiveSiteListToServer = async (
    companyId: string,
    archived: boolean,
    siteIds: string[]
  ) => {
    setShowLoader(true);

    const params = {
      companyId,
      archived,
      siteIds,
    };
    const response = await bulkArchiveSiteList(params);

    if (response.status === WebServiceStatus.success) {
      // All requests have been completed successfully
      fetchSiteListFromServer(false);
      setSnackbarContent({
        title: `${archived ? "Archive" : "Unarchive"} ${
          siteIds.length > 1 ? "Sites" : "Site"
        }!`,
        message: `${
          siteIds.length > 1 ? "Sites are" : "Site is"
        } successfully ${archived ? "archived" : "unarchived"}.`,
        isError: false,
      });
    } else {
      // Atleast one of the request set is failed
      const errorMessage = response.error ?? GENERIC_ERROR_MESSAGE;
      setSnackbarContent({
        title: `${archived ? "Archive" : "Unarchive"} failed for ${
          siteIds.length > 1 ? "some sites" : "this site"
        }.`,
        message: errorMessage,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getAddSiteDialog = () => {
    return (
      <CustomDialogBox title="Add Site" width="1204px" openDialog={true}>
        <AddSiteDialogContent
          listOfCustomColumns={
            visibleCustomColumns
              ? visibleCustomColumns.map((column) => column.headerName).sort()
              : []
          }
          onCloseDialog={() => {
            setOpenAddSiteDialog(false);
          }}
          getAddSiteData={addNewSite}
        />
      </CustomDialogBox>
    );
  };

  // Opens the map dialog
  const getMapDialogBox = () => {
    if (!cellInEdit) {
      setShowMapDialog(false);
      return <></>;
    }

    const { id } = cellInEdit;
    const rowIndex = validatedRowData.findIndex((value) => value.id === id);
    if (rowIndex === -1) {
      return <></>;
    }
    var currentRow = { ...validatedRowData[rowIndex] };
    const { site, address, address2, city, state, zip } = currentRow;

    const isValidAddress = currentRow.addressValidity ?? true;
    const siteAddress = `${address ?? ""} ${city ?? ""} ${state ?? ""} ${
      zip ?? ""
    }`;
    var newRows = [...validatedRowData];
    newRows.splice(rowIndex, 1);
    return (
      <CustomDialogBox title="Update Address" width="1204px" openDialog={true}>
        <UpdateAddressDialogContent
          isValidAddress={isValidAddress}
          closeDialog={() => {
            setShowMapDialog(false);
          }}
          disableLocationName={false}
          siteAddress={siteAddress}
          suiteNo={address2}
          siteName={site}
          updatedSiteDetails={async (
            coordinates?: LatLng,
            addressComp?: SiteAddressProp,
            siteName?: string,
            suiteNo?: string
          ) => {
            currentRow.address2 = suiteNo;
            currentRow.coordinates = coordinates;
            currentRow.site = siteName;
            currentRow.address = addressComp.address;
            currentRow.city = addressComp.city;
            currentRow.state = addressComp.state;
            currentRow.zipcode = addressComp.zipCode;
            const duplicateRowIndex = getRowIndexWithDuplicateSiteName(
              newRows,
              currentRow.site,
              currentRow.sitenumber
            );
            if (duplicateRowIndex === -1) {
              // Updating the row data
              setUpdatedRowData(currentRow);
            } else {
              setSnackbarContent({
                title: "Attention!",
                message: "Duplicate site name found.",
                isError: true,
              });
            }
            setCellInEdit(null);
          }}
        />
      </CustomDialogBox>
    );
  };

  const getActionBar = () => {
    const currentlySelectedRows = validatedRowData.filter((row) =>
      selectedRows.includes(row.id)
    );
    const hasArchivedRows = currentlySelectedRows.some(
      (row) => row.archived.toString().toLowerCase() === "yes"
    );
    const hasNonArchivedRows = currentlySelectedRows.some(
      (row) => row.archived.toString().toLowerCase() === "no"
    );
    return (
      <SiteListActionBar
        onButtonClick={(type: SiteListActionBarButtonType) => {
          onActionBarClick(type);
        }}
        disableArchiveButton={hasArchivedRows}
        disableUnArchiveButton={hasNonArchivedRows}
      />
    );
  };

  const getTextFieldWithDropDown = () => {
    return (
      <MDBox display="flex" mr="10px" flexGrow="1">
        <MDBox display="flex" alignItems="start" flexShrink="0">
          <TextField
            fullWidth
            value={searchText}
            placeholder="Search By Site Name"
            sx={searchBarStyle}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              setSearchTextChanged(true);
              setSearchText(event.target.value);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search fontSize="medium" sx={{ color: "#344767" }} />
                </InputAdornment>
              ),
            }}
          />
        </MDBox>
        <CustomDropdown
          title={"Site Status"}
          selectedItem={{ value: siteStatus, displayTitle: siteStatus }}
          availableValues={availableSiteStatus.map((status) => {
            return { value: status, displayTitle: status };
          })}
          onChange={handleDropDownClick}
          prefixTitle={true}
        />
      </MDBox>
    );
  };

  const getTopBar = () => {
    return (
      <SiteListTopBar
        disableManageColumnButton={nonValidatedRowData.length === 0}
        onButtonClick={(
          type: SiteListTopBarButtonType,
          event: React.MouseEvent<HTMLButtonElement, MouseEvent>
        ) => {
          onTopBarClick(type, event);
        }}
      />
    );
  };

  const getClearAllFiltersButton = () => {
    return (
      <MDBox ml={1} mr="auto" flexShrink="0">
        <StyledButtonWithIcon
          iconPath={mdiFilterOff}
          onClick={onClearAllFilterButtonClick}
        >
          {`Clear All Filters (${selectedFilters.length})`}
        </StyledButtonWithIcon>
      </MDBox>
    );
  };
  const getCustomColumnDataForSite = (
    state: any,
    customColumn: GridColDef[]
  ) => {
    let customObject: { [key: string]: string } = {};
    customColumn.forEach((item) => {
      customObject[item.field] = state[item.field];
    });
    return customObject;
  };

  const addNewSite = async (props: AddSiteState) => {
    const newRows = [...nonValidatedRowData];
    const customColumnData = getCustomColumnDataForSite(
      props,
      visibleCustomColumns
    );
    const newRow = {
      companyId: companyId,
      siteName: props.site,
      siteNumber: props.sitenumber,
      address: props.address,
      addressTwo: props.address2,
      city: props.city,
      stateOrProvinceTwoCharacterCode: props.state,
      zipOrPostalCode: props.zipCode,
      notes: props.instruction,
      meta: customColumnData,
    };
    const duplicateRowIndex = getRowIndexWithDuplicateSiteName(
      newRows,
      props.site,
      props.sitenumber
    );
    // const newRow = { id: newRows.length, ...props };
    if (duplicateRowIndex === -1) {
      // No duplicate rows
      addNewSiteToServer(newRow);
    } else {
      setAddNewSiteData(newRow);
    }
  };

  const addNewSiteToServer = async (newRow: any) => {
    setShowLoader(true);
    const response = await addSiteList(newRow);
    if (response.status === WebServiceStatus.success) {
      fetchSiteListFromServer(false);
      setSnackbarContent({
        title: "Add Site!",
        message: "Site added successfully",
        isError: false,
      });
    } else {
      setSnackbarContent({
        title: "Attention!",
        message: response.error,
        isError: true,
      });
    }
    setShowLoader(false);
  };

  const getReplaceSiteConfirmation = () => {
    return (
      <CustomDialogBox
        title="Duplicate Site Found"
        width="450px"
        openDialog={true}
      >
        <ConfirmationDialog
          message="Duplicate site name found. Please note the old data shall be overwritten by the new data. Do you wish to continue?"
          buttonName="Yes, merge"
          onClose={() => {
            setAddNewSiteData(null);
          }}
          onClick={() => {
            const index = nonValidatedRowData.findIndex((row) => {
              return (
                `${row.site} ${row.sitenumber}`.toLowerCase() ===
                `${addNewSiteData.siteName} ${addNewSiteData.siteNumber}`.toLowerCase()
              );
            });
            if (index !== -1) {
              const rowTobeEdited = nonValidatedRowData[index];
              const data = {
                id: rowTobeEdited.id,
                address: addNewSiteData.address,
                city: addNewSiteData.city,
                state: addNewSiteData.stateOrProvinceTwoCharacterCode,
                site: addNewSiteData.siteName,
                sitenumber: addNewSiteData.siteNumber,
                address2: addNewSiteData.addressTwo,
                zipcode: addNewSiteData.zipOrPostalCode,
                archived: rowTobeEdited.archived,
                internalnote: addNewSiteData.notes,
                ...addNewSiteData.meta,
              };
              setUpdatedRowData(data);
              setAddNewSiteData(null);
            }
          }}
        />
      </CustomDialogBox>
    );
  };

  ////////////////////////// FILTER CUSTOMISATION //////////////////////////

  /// Handles the onClick of "Filter" icon in the column header
  const handleFilterIconClick = (field: string) => {
    // Helps to show the popup. If the field is already showing, setting null will hide the popup
    setPopperName(popperName === field ? null : field);
  };

  const getListStyledPopper = () => {
    var children: JSX.Element = <></>;
    switch (popperName) {
      case "managecolumn":
        children = getManageColumnPopperContent();
        break;
      case "sitename":
      case "site":
      case "sitenumber":
      case "address":
      case "address2":
      case "city":
      case "state":
      case "zipcode":
        children = getTextFieldStylePopperContent();
        break;
      default:
        // Filtering data and sending it dynamically
        children = getListStyledPopperContent();
        break;
    }

    return (
      <CustomPopper
        onClickAway={(event: MouseEvent | TouchEvent) => {
          handleClosePopper(event);
        }}
        shouldOpen={true}
        anchorElement={anchorElement}
        id={popperName}
        placement={popperName === "managecolumn" ? "bottom" : "right"}
      >
        {children}
      </CustomPopper>
    );
  };

  const getTextFieldStylePopperContent = () => {
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    return (
      <FilterByTextContent
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onTextChange={(text) => {
          const filterObject: GridFilter = {
            field: popperName,
            filters: [] as any[],
          };
          const indexOfItem = selectedFilters.findIndex(
            (item) => item.field === popperName
          );
          var updatedFilters = [...selectedFilters];
          if (text.isEmpty()) {
            // Filters on this columns has been removed
            if (indexOfItem !== -1) {
              // Item already available. Removing the item
              updatedFilters.splice(indexOfItem, 1);
            }
          } else {
            if (indexOfItem !== -1) {
              updatedFilters[indexOfItem] = filterObject;
            } else {
              updatedFilters.push(filterObject);
            }
          }
          setSelectedFilters(updatedFilters);
          handleTextFieldPopperChanges(popperName, text);
        }}
        shouldShowRemoveColumnButton={false}
        searchedText={getPreviouslySearchedTextForSiteList(
          siteListsState,
          popperName
        )}
      />
    );
  };

  const handleTextFieldPopperChanges = (fieldName: string, newText: string) => {
    if (fieldName === "sitename") {
      setSearchText("");
      dispatchSiteListFetchAction({
        type: SiteListPageActionType.searchTextChanged,
        payload: {
          ...siteListsState,
          search: "",
        },
      });
    }
    const payload = getPayloadForTextFieldChangesForSiteList(
      siteListsState,
      fieldName,
      newText
    );
    dispatchSiteListFetchAction({
      type: SiteListPageActionType.textBasedFilterApplied,
      payload: payload,
    });
  };
  const getListStyledPopperContent = () => {
    var popperContent: string[] = [];
    switch (popperName) {
      case "country":
        popperContent = ["US", "CA"];
        break;
      case "archived":
        // Archived array has boolean value. So converting it to corresponding string values
        popperContent = ["Yes", "No"];
        break;
      default:
        popperContent = nonFilteredRowData.map(
          (row) => row[popperName]?.toString() ?? ""
        );
        break;
    }
    const headerName: string = columnData.filter(
      (data) => data.field === popperName
    )[0].headerName;
    const columnName = headerName ?? "";
    const selections = selectedFilters.filter(
      (filter) => filter.field === popperName
    );
    // fetching the previous selections
    const previousSelections =
      selections.length > 0 ? selections[0].filters : [];
    // Removing non empty items
    const contentSet = new Set(
      popperContent.filter((item) => item.trim().length > 0)
    );
    // Retreiving the unique items from the list
    const uniqueContents = [...new Set(contentSet)];

    return popperName === "archived" ? (
      <SingleSelectionListFilter
        selectionList={uniqueContents}
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onSelectionChange={onListStyledFilterChange}
        shouldShowRemoveColumnButton={false}
        previousSelectedOption={
          previousSelections.length > 0 ? previousSelections[0] : ""
        }
      />
    ) : (
      <CustomFilterContent
        customSelectorList={uniqueContents}
        removeColumn={() => {}}
        columnName={columnName}
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        onFilterChange={onListStyledFilterChange}
        shouldShowRemoveColumnButton={false}
        previouslySelectedOptions={previousSelections}
      />
    );
  };

  const onListStyledFilterChange = (newFilters: string[]) => {
    const filterObject: GridFilter = { field: popperName, filters: newFilters };
    const indexOfItem = selectedFilters.findIndex(
      (item) => item.field === popperName
    );
    var updatedFilters = [...selectedFilters];
    if (newFilters.length <= 0) {
      // Filters on this columns has been removed
      if (indexOfItem !== -1) {
        // Item already available. Removing the item
        updatedFilters.splice(indexOfItem, 1);
      }
    } else {
      if (indexOfItem !== -1) {
        updatedFilters[indexOfItem] = filterObject;
      } else {
        updatedFilters.push(filterObject);
      }
    }
    setSelectedFilters(updatedFilters);
    applyFilterOnGrid(updatedFilters);
  };

  // Apply filters on the grid, for survey types and vueStatuses
  const applyFilterOnGrid = (allFilters: any[]) => {
    var archived: boolean = null as boolean;
    var payload = { ...siteListsState };
    for (var index = 0; index < allFilters.length; index++) {
      const { field, filters } = allFilters[index];
      switch (field) {
        case "archived":
          if (filters.length > 0) {
            archived = filters[0] === "Yes";
          }
          payload = { ...payload, archive: archived };
          break;
        case "country":
          var countries: string[] = [];
          if (filters.length > 0) {
            countries = filters;
          }
          payload = { ...payload, country: countries };
          break;
        default:
          break;
      }
    }
    dispatchSiteListFetchAction({
      type: SiteListPageActionType.listBasedFilterApplied,
      payload: payload,
    });
  };

  /// Returns the content for add column popup
  const getManageColumnPopperContent = () => {
    const allColumnNames = setOfGridColumns.current
      .filter((column) => !siteListPinnedColumns.includes(column.field))
      .map((column) => column.headerName ?? "")
      .sort((a, b) => alphanumericSort(a, b));
    return (
      <ManageColumnContent
        closePopper={(event: Event | React.SyntheticEvent<Element, Event>) => {
          handleClosePopper(event);
        }}
        manageColumn={(newColumns) => {
          const currentColumnDefinition = [...setOfGridColumns.current];
          currentColumnDefinition.sort((a, b) => {
            return (
              newColumns.indexOf(a.headerName) -
              newColumns.indexOf(b.headerName)
            );
          });
          setColumnData(currentColumnDefinition);
          const visibleColumns = setOfGridColumns.current.filter((column) =>
            newColumns.includes(column.headerName)
          );
          // Filtering out the custom columns and updates the visibility
          const customColumns = setOfGridColumns.current.filter((column) => {
            const { field } = column;
            return !knownSitelistColumns.some(
              (header) => getFieldNameFromHeader(header) === field
            );
          });
          setVisibleCustomColumns(customColumns);
          setVisibleColumnNames(
            visibleColumns
              .sort((a, b) => alphanumericSort(a.headerName, b.headerName))
              .map((column) => column.headerName.toUpperCase())
          );
        }}
        availableOptions={allColumnNames}
        previouslySelectedItems={visibleColumnNames}
      />
    );
  };

  /// Handles the close of popper component
  const handleClosePopper = (event: Event | React.SyntheticEvent) => {
    // Helps to hide the popups
    setPopperName(null);
  };

  // Sets the sort column values
  const setSortColumn = (name: string, isAscendingOrder: boolean) => {
    dispatchSiteListFetchAction({
      type: SiteListPageActionType.sortColumnChanged,
      payload: {
        ...siteListsState,
        sortColumnDisplayName: name.capitalizeWords(),
        sortIsAscending: isAscendingOrder,
      },
    });
  };
  ////////////////////////// GRID CUSTOMISATION //////////////////////////

  const getIconType = (
    cellData: EditableCell,
    params: GridRenderCellParams
  ) => {
    // Checks whether the column needs to be edited
    const iconType = cellData?.editIcon ?? CellEditIconType.edit;
    return iconType;
  };

  const getIconsFromCellType = (
    type: CellEditIconType,
    onClick: (buttonType: EditButtonType) => void
  ): JSX.Element => {
    var icon: JSX.Element;
    switch (type) {
      case CellEditIconType.map:
        icon = (
          <IconButton
            onClick={(event) => {
              event.stopPropagation();
              onClick(EditButtonType.map);
            }}
          >
            <Icon path={mdiMapMarker} size={0.72} />
          </IconButton>
        );
        break;
      default:
        icon = (
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation();
              onClick(EditButtonType.edit);
            }}
          >
            <Icon path={mdiPencil} size={0.72} />
          </IconButton>
        );
        break;
    }
    return icon;
  };

  const editButtonClicked = (
    buttonType: EditButtonType,
    cellParams: GridRenderCellParams
  ) => {
    const { id, field } = cellParams;
    switch (buttonType) {
      case EditButtonType.map:
        setShowMapDialog(true);
        break;
      default:
        if (headerInEdit.isEmpty()) {
          gridRef.current.startCellEditMode({ id: id, field: field });
        }
        break;
    }
  };

  // Handles the changes on header editing
  const headerChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setEditedHeader(value.toUpperCase());
  };

  // saves the changes on currently editing header
  const saveHeaderEditing = () => {
    if (headerInEdit.isNotEmpty()) {
      // A header editing is in progress. Finding the index of edited header
      const index = columnData.findIndex(
        (column) => column.field.toUpperCase() === headerInEdit.toUpperCase()
      );
      // If the new value is empty, we will keep the old value
      if (index !== -1 && editedHeader.trim().isNotEmpty()) {
        var updatedColumnData = [...columnData];
        // Updating the new column header
        updatedColumnData[index].headerName = editedHeader;
        setColumnData(updatedColumnData);
      }

      // On completion resetting the editing feature
      setHeaderInEdit("");
      setEditedHeader("");
    }
  };

  /// Defines the custom headers
  const getCustomHeader = (params: GridColumnHeaderParams) => {
    const { colDef, field } = params;
    const headerName = colDef.headerName;
    var iconButtons: JSX.Element = <></>;
    const enableHeaderEditing = false;
    // Checks whether the headers are editable or not
    if (enableHeaderEditing && editableHeaders && editableHeaders.length > 0) {
      var iconType = CellEditIconType.edit;

      const headerCellData = editableHeaders.find(
        (cell) => cell.fieldName.toLowerCase() === field.toLowerCase()
      );
      if (headerCellData) {
        // Checks whether the column needs to be edited
        iconType = headerCellData?.editIcon ?? CellEditIconType.edit;
        iconButtons = getIconsFromCellType(iconType, () => {
          setHeaderInEdit(field);
          // Keeps the header name in uppercase for editing
          setEditedHeader(headerName.toUpperCase());
        });
      }
    }
    // TODO: REMOVE THE CUSTOM COLUMN SORT FILTER PREVENTION IN PHASE 2
    const customColumnFields = allCustomColumns.map((column) => column.field);
    const isEditing = headerInEdit.isNotEmpty();
    // Columns that do not have sort & filter options
    const filterDisabledColumns = [
      ...customColumnFields,
      "latlong",
      "internalnote",
      "lastvisit"
    ];
    const sortDisabledColumns = [
      ...customColumnFields,
      "latlong",
      "country",
      "internalnote",
      "lastvisit"
    ];
    const showFilterIcon =
      nonFilteredRowData.length > 0 && !filterDisabledColumns.includes(field);
    const showSortIcon =
      nonFilteredRowData.length > 0 && !sortDisabledColumns.includes(field);
    const showRearrangeIcon = !siteListPinnedColumns.includes(field);
    return (
      <MDBox>
        {isEditing && headerInEdit === field ? (
          <MDInput
            sx={{
              ...inputEditStyles,
              "& fieldset": {
                border: "none",
              },
            }}
            autoFocus
            value={editedHeader}
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              event.stopPropagation();
            }}
            onChange={headerChanged}
            onBlur={saveHeaderEditing}
          />
        ) : (
          <>
            <strong>{headerName.toUpperCase()}</strong>
            {showSortIcon && (
              <HeaderSortIconButton
                onClickSort={(
                  isAscending: boolean,
                  newSortModel: GridSortModel
                ) => {
                  setSortColumn(headerName, !isAscending);
                  setSortModel(newSortModel);
                }}
                sortModel={sortModel}
                field={field}
              />
            )}
            {showFilterIcon && (
              <IconButton
                size="small"
                onClick={(event) => {
                  event.stopPropagation();
                  setAnchorElement(event.currentTarget);
                  handleFilterIconClick(field);
                }}
              >
                <Icon path={mdiFilter} size={0.72} />
              </IconButton>
            )}
            {showRearrangeIcon && (
              <IconButton
                size="small"
                sx={{ ml: 0.5 }}
                onClick={(event) => {
                  event.stopPropagation();
                }}
              >
                <Icon path={mdiCursorMove} size={0.72} />
              </IconButton>
            )}
          </>
        )}
        {iconButtons}
      </MDBox>
    );
  };

  // Defines the custom cells in the grid
  const getCustomCells = (params: GridRenderCellParams) => {
    const { field, value } = params;
    if (field === "sitename") {
      return (
        <MDBox className="MuiDataGrid-cellContent">
          <Link
            sx={{ ...linkElementStyle, cursor: "pointer" }}
            onClick={() => {
              onClickLinkElement(params);
            }}
            variant="button"
            color="primary"
            fontWeight="regular"
            underline="always"
            ml={1}
          >
            {value}
          </Link>
        </MDBox>
      );
    } else if (field === "lastvisit") {
      return (
        <MDBox
          className="MuiDataGrid-cellContent"
          display="flex"
          alignItems="center"
          minWidth="0"
        >
          <MDBox sx={textEllipsis}>{value ?? "-"}</MDBox>
        </MDBox>
      );
    }

    var iconButtons: JSX.Element = <></>;
    // Checks whether the cells are editable or not
    if (editableColumns && editableColumns.length > 0) {
      var iconType = CellEditIconType.edit;

      const cellData = editableColumns.find(
        (cell) => cell.fieldName.toLowerCase() === field.toLowerCase()
      );
      if (cellData) {
        // Checks whether the column needs to be edited
        iconType = getIconType(cellData, params);
        iconButtons = getIconsFromCellType(iconType, (buttonType) => {
          setCellInEdit(params);
          editButtonClicked(buttonType, params);
        });
      }
    }

    return (
      <MDBox
        className="MuiDataGrid-cellContent"
        display="flex"
        alignItems="center"
        minWidth="0"
      >
        <MDBox sx={textEllipsis}>{value}</MDBox>
        <MDBox sx={cellEditIconsStyle}>{iconButtons}</MDBox>
      </MDBox>
    );
  };

  /// Handles the navigation to site detail page
  const onClickLinkElement = (params: GridRenderCellParams) => {
    navigate(`${RoutePath.siteLists}/site-details`, {
      state: {
        jobSiteID: params.row.id,
        siteName: params.row.siteNameNumber,
      },
    });
  };

  const renderedColumns = columnData.map((data) => {
    data.renderCell = getCustomCells;
    data.renderHeader = getCustomHeader;
    return data;
  });

  const getRowClassName = (params: GridRowClassNameParams<any>): string => {
    const isInvalidRow =
      invalidRowData.filter((row) => row.id === params.row.id).length > 0;
    return isInvalidRow ? "invalid-grid-row" : "";
  };

  /// Handles the onChange of check box
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    const listOfSelectedRowIds: GridRowId[] = validatedRowData.map(
      (row) => row.id
    );
    setSelectedRows(checked ? listOfSelectedRowIds : []);
  };

  /// Handles the change in rows selection
  const handleRowSelectionChange = (
    listOfSelectedRowIds: GridRowSelectionModel
  ) => {
    setSelectedRows(listOfSelectedRowIds);
    setSelectAll(listOfSelectedRowIds.length === validatedRowData.length);
  };

  const onProcessRowUpdate = (updatedRow: any, originalRow: any): any => {
    const index = nonValidatedRowData.findIndex(
      (row) => row.id === updatedRow.id
    );
    if (index !== -1) {
      setUpdatedRowData(updatedRow);
    }
    // For invalid cases
    return originalRow;
  };

  // Should update the column size, but prevent the frequent API calls
  const handleColumnResize = (params: GridColumnResizeParams) => {
    const { colDef } = params;
    const { headerName, width } = colDef;
    setColumnWidthObject({
      columnName: headerName,
      columnWidth: Math.ceil(width),
    });
  };

  // Handles the column reorder functionality
  const handleColumnReOrder = (params: GridColumnOrderChangeParams) => {
    const { oldIndex, targetIndex } = params;

    var newColumnDefinition = [...columnData];
    // Swapping items to update the database
    const temp = newColumnDefinition[targetIndex - 1];
    newColumnDefinition[targetIndex - 1] = newColumnDefinition[oldIndex - 1];
    newColumnDefinition[oldIndex - 1] = temp;
    setColumnData(newColumnDefinition);
    const newVisibleColumns = newColumnDefinition
      .filter(
        (column) =>
          !column.hideable && !siteListPinnedColumns.includes(column.field)
      )
      .map((data) => data.headerName);
    setVisibleColumnNames(newVisibleColumns);
  };

  // Update a row
  useEffect(() => {
    const editRow = async (updatedRow: any) => {
      const rowIndex = nonValidatedRowData.findIndex(
        (value) => value.id === updatedRow.id
      );
      if (rowIndex === -1) {
        return <></>;
      }
      const newRows = [...nonValidatedRowData];
      newRows.splice(rowIndex, 1);
      const duplicateRowIndex = getRowIndexWithDuplicateSiteName(
        newRows,
        updatedRow.site,
        updatedRow.sitenumber
      );
      if (duplicateRowIndex === -1) {
        const index = nonValidatedRowData.findIndex(
          (row) => row.id === updatedRow.id
        );
        if (index !== -1) {
          const customColumnData = getCustomColumnDataForSite(
            updatedRow,
            allCustomColumns
          );
          setShowLoader(true);
          const data = {
            siteNumber: updatedRow.sitenumber,
            siteName: updatedRow.site,
            addressTwo: updatedRow.address2,
            id: updatedRow.id,
            address: updatedRow.address,
            city: updatedRow.city,
            stateOrProvinceTwoCharacterCode: updatedRow.state,
            zipOrPostalCode: updatedRow.zipcode,
            archived: updatedRow.archived === "Yes",
            notes: updatedRow.internalnote,
            meta: customColumnData,
          };
          const response = await editSiteList(data);
          if (response.status === WebServiceStatus.success) {
            var dataToBeUpdated = [...nonValidatedRowData];
            dataToBeUpdated[index] = getGridDataForSiteList(response.data);
            setNonValidatedRowData(dataToBeUpdated);
            setSnackbarContent({
              title: "Update Site Info!",
              message: "Site updated successfully.",
              isError: false,
            });
          } else {
            setSnackbarContent({
              title: "Attention!",
              message: response.error,
              isError: true,
            });
          }
          setUpdatedRowData(null);
          setShowLoader(false);
        }
      } else {
        setSnackbarContent({
          title: "Attention!",
          message: "Duplicate site name found.",
          isError: true,
        });
      }
    };
    if (updatedRowData !== null) {
      editRow(updatedRowData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedRowData]);

  const getNoRowsMessage = () => {
    return (
      <MDBox position="relative" top="30%">
        <Typography
          textAlign="center"
          variant="h6"
          sx={{ fontWeight: "500", color: "#939393" }}
        >
          {nonValidatedRowData.length > 0
            ? "No rows available"
            : "To begin, please add a site or bulk import a site list"}
        </Typography>
      </MDBox>
    );
  };

  const getSiteListFooter = () => {
    return (
      <MDBox display="flex" alignItems="center">
        <SiteListBottomBar totalSites={metaValues.totalElements} />
      </MDBox>
    );
  };

  const handleOnRowsScrollEnd: DataGridPremiumProps["onRowsScrollEnd"] =
    async () => {
      if (
        validatedRowData.length < metaValues.totalElements &&
        metaValues.pageNumber < metaValues.totalPages
      ) {
        await fetchSiteListFromServer(true);
      }
    };

  // Search field changes
  useEffect(() => {
    if (searchTextChanged) {
      // performs filter on given field and text
      const performSearchBySiteName = (searchText: string) => {
        dispatchSiteListFetchAction({
          type: SiteListPageActionType.searchTextChanged,
          payload: {
            ...siteListsState,
            search: searchText,
          },
        });
      };
      /// The method delays the callback for 700 millseconds
      const delaySearchAction = setTimeout(() => {
        performSearchBySiteName(searchText.trim());
      }, TEXTFIELD_CHANGE_DELAY);
      return () => clearTimeout(delaySearchAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  // Use effect to validate the rows
  useEffect(() => {
    const validateAllRows = async () => {
      var newlyValidated: any[] = [];
      var invalidRows: any[] = [];
      setLoader(true);
      const responses: AddressValidationResult[] = await Promise.all(
        nonValidatedRowData.map((currentRow) => {
          const { address, city, state, country, zipcode, latlong } =
            currentRow;
          return isContainEmptyAddressComponent(
            address,
            city,
            state,
            country,
            zipcode,
            latlong,
            false
          );
        })
      );
      setLoader(false);
      responses.forEach((validationResult, index) => {
        const currentRow = nonValidatedRowData[index];
        const basicValidity = hasBasicData(currentRow);
        const validAddress = validationResult.isValid;
        const rowItem = {
          ...currentRow,
          addressValidity: validAddress,
          basicValidity: basicValidity,
          ...validationResult.address,
        };
        newlyValidated[index] = rowItem;
        if (!validAddress || !basicValidity) {
          // Adding invalid rows
          invalidRows.push(rowItem);
        }
        if (newlyValidated.length === nonValidatedRowData.length) {
          setValidatedRowData(newlyValidated);
          setInvalidRowData(invalidRows);
          setNonFilteredRowData(newlyValidated);
        }
      });
    };
    if (nonValidatedRowData.length === 0) {
      setValidatedRowData([]);
    } else {
      validateAllRows();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nonValidatedRowData]);

  return (
    <>
      {openAddSiteDialog && getAddSiteDialog()}
      {openBulkImportDialog && getBulkImportDialog()}
      {showConfirmation.visible && getActionButtonConfirmationDialog()}
      {addNewSiteData !== null && getReplaceSiteConfirmation()}
      {showMapDialog && getMapDialogBox()}
      {popperName && getListStyledPopper()}
      <MDBox>
        {showLoader && <CustomIndicator />}
        <MDBox display="flex" alignItems="start" pl={2} pb={1.4}>
          <FormGroup sx={{ flexShrink: "0" }}>
            <FormControlLabel
              control={
                <Checkbox checked={selectAll} onChange={handleCheckboxChange} />
              }
              sx={checkBoxStyle}
              label={
                selectedRows.length > 0
                  ? `${
                      selectAll ? metaValues.totalElements : selectedRows.length
                    }/${metaValues.totalElements}`
                  : ""
              }
            />
          </FormGroup>
          <MDBox display="flex" flexWrap="wrap" marginTop="-8px">
            {selectedRows.length > 0 ? (
              <MDBox marginTop="10px"> {getActionBar()} </MDBox>
            ) : (
              <MDBox marginTop="10px"> {getTextFieldWithDropDown()} </MDBox>
            )}
            {selectedFilters.length > 0 && (
              <MDBox marginTop="10px"> {getClearAllFiltersButton()} </MDBox>
            )}
          </MDBox>
          {getTopBar()}
          {/* {errorMessage.length > 0 && getErrorMessage()} */}
        </MDBox>
        <Card
          sx={{
            minHeight: `calc(100vh - 156px)`,
            height: `calc(100vh - 156px)`,
            justifyContent: "flex-start",
            position: "relative",
          }}
        >
          <DataGridPremium
            apiRef={gridRef}
            columns={renderedColumns}
            rows={validatedRowData}
            columnVisibilityModel={columnVisibilityModel}
            checkboxSelection
            disableColumnMenu
            disableColumnSorting
            sx={{
              ...styleForUploadTemplateGrid,
              ...gridStyle,
              ...dataGridCellButtonStyle,
            }}
            disableRowSelectionOnClick
            onRowSelectionModelChange={handleRowSelectionChange}
            rowSelectionModel={selectedRows}
            sortModel={sortModel}
            initialState={{
              density: "compact",
              pinnedColumns: {
                left: [
                  GRID_CHECKBOX_SELECTION_COL_DEF.field,
                  ...siteListPinnedColumns,
                ],
              },
            }}
            slots={{
              footer: getSiteListFooter,
              noRowsOverlay: getNoRowsMessage,
            }}
            slotProps={{
              loadingOverlay: {
                variant: "linear-progress",
                noRowsVariant: "linear-progress",
              },
            }}
            onCellEditStart={(
              params: GridCellEditStartParams,
              event: MuiEvent
            ) => {
              // Preventing other edit options like double click/
              event.defaultMuiPrevented = true;
            }}
            processRowUpdate={onProcessRowUpdate}
            onProcessRowUpdateError={(error) => {
              console.log(error);
            }}
            loading={loader}
            getRowClassName={getRowClassName}
            sortingMode="server"
            filterMode="server"
            onRowsScrollEnd={handleOnRowsScrollEnd}
            onColumnResize={handleColumnResize}
            onColumnOrderChange={handleColumnReOrder}
          />
        </Card>
      </MDBox>
      <CustomSnackbar
        snackbarContent={snackbarContent}
        onClose={() => {
          setSnackbarContent(null);
        }}
      />
    </>
  );
};

export default SiteListDataGrid;
