import {
  CardContent,
  Grid,
  Card,
  CircularProgress,
  Tooltip,
  Link,
} from "@mui/material";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import {
  cardContentStyle,
  loaderStyle,
  photoSectionCardStyle,
} from "../../vues/styles/VueDetailStyles";
import { CustomBadge } from "pages/dashboard/components/CustomBadge";
import { tooltipStyles } from "../../vues/vue-grid/components/VueActionBar";
import CustomCheckbox from "pages/dashboard/components/CustomCheckBox";
import { ClientMediaInfo } from "../utils/interfaces";
import { TagItem } from "pages/settings/interfaces/interfaces";
import { linkElementStyle } from "@ivueit/vue-engine";
import { useNavigate } from "react-router";
import { RoutePath } from "../../../../../constants";

const selectedStyle = {
  boxShadow: "0 0 0 4px #63b967",
};
const escalationStyle = {
  boxShadow: "0 0 0 4px #AE1709",
};
const hideStyle = {
  "> .blurContent": {
    filter: "blur(4.2px)",
  },
  overflow: "hidden",
  position: "relative",
  cursor: "default",
  ":before": {
    display: "block",
    content: "''",
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "rgba(0, 0, 0, 0.35)",
    zIndex: "6",
  },
  ":after": {
    zIndex: "3",
  },
};

interface Props {
  clientMediaInfo: ClientMediaInfo;
  children: JSX.Element;
  enableSelection: boolean;
  handleMediaClick: (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  isSelected: boolean;
}

export const MediaContainer = ({
  clientMediaInfo,
  children,
  enableSelection,
  handleMediaClick,
  isSelected,
}: Props) => {
  const navigate = useNavigate();
  const photoTagsList = clientMediaInfo.appliedMediaTags as TagItem[];

  const getTypography = (label: string) => {
    return (
      <MDTypography sx={{ ...cardContentStyle }} variant="caption">
        {label}
      </MDTypography>
    );
  };

  // Prepares the card style for the UI
  const getCardStyle = () => {
    var style = {
      cursor: "pointer",
      borderRadius: "1px !important",
      ".MuiCardMedia-root": {
        margin: "0px !important",
        borderRadius: "0px !important",
        border: "1px solid #C7CCD0",
      },
      ".MuiCardContent-root": {
        backgroundColor: "#000000",
        borderRadius: "0px !important",
        padding: "10px !important",
      },
    };

    if (isSelected) {
      style = { ...style, ...selectedStyle };
    } else if (clientMediaInfo.escalated || clientMediaInfo.adminEscalated) {
      // escalation border is applicable only when the photo is NOT selected
      style = { ...style, ...escalationStyle };
    }
    if (clientMediaInfo.adminHide || clientMediaInfo.clientHide) {
      if (clientMediaInfo.mimeType.toLowerCase() === "video") {
        style = { ...style, ...hideStyle };
      } else {
        style = { ...style, ...hideStyle, cursor: "pointer" };
      }
    }
    return style;
  };

  const getTimeString = (): string => {
    // Time in nano seconds
    return (
      clientMediaInfo.capturedAt &&
      clientMediaInfo.capturedAt.formatUTCNanoSecondsToString(
        "MM/DD/YYYY - hh:mm A",
        true
      )
    );
  };

  const getTags = () => {
    const maxVisibleTags = 3;
    return (
      <>
        {photoTagsList
          .slice(0, maxVisibleTags)
          .map((tag: TagItem, index: number) => (
            <CustomBadge
              key={index}
              max={parseInt(tag.name)}
              content={tag.name}
              sx={{ mr: "4px", ".MuiBadge-badge": { pt: "12px", pb: "12px" } }}
            />
          ))}
        {photoTagsList.length > maxVisibleTags && (
          <Tooltip
            title={photoTagsList
              .slice(maxVisibleTags)
              .map((tag: TagItem, index: number) => {
                return (
                  <span key={index}>
                    <b key={tag.id}>
                      {tag.name}
                      <br />
                    </b>
                  </span>
                );
              })}
            arrow
            placement="top"
            componentsProps={{
              tooltip: {
                sx: { ...tooltipStyles },
              },
            }}
            disableHoverListener={false}
          >
            <span>
              <MDTypography
                sx={{
                  pl: 0.8,
                  fontSize: "14px",
                  fontWeight: "regular",
                  color: "#FFFFFF",
                }}
              >
                +{photoTagsList.length - maxVisibleTags} more
              </MDTypography>
            </span>
          </Tooltip>
        )}
      </>
    );
  };

  const getSiteNameNumber = () => {
    return (
      <Link
        sx={{ ...linkElementStyle, fontWeight: "500", cursor: "pointer" }}
        color="primary"
        variant="h6"
        underline="always"
        onClick={(event) => {
          event.stopPropagation();
          navigate(`${RoutePath.mediahub}/site-details`, {
            state: {
              jobSiteID: clientMediaInfo.jobsiteId,
              siteName: clientMediaInfo.siteNameNumber,
            },
          });
        }}
        pr={1}
      >
        {clientMediaInfo.siteNameNumber.toUpperCase()}
      </Link>
    );
  };

  return (
    <MDBox
      position="relative"
      sx={{
        display: "flex",
        flexGrow: "1",
        ".MuiPaper-root": {
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
        },
      }}
    >
      {clientMediaInfo ? (
        <Card sx={getCardStyle()} onClick={handleMediaClick}>
          {enableSelection && (
            <MDBox
              className="noBlur"
              sx={{
                position: "absolute",
                top: "15px",
                right: "0px",
                zIndex: "301",
                ".MuiCheckbox-root:not(.Mui-checked)": {
                  ".MuiSvgIcon-root": {
                    background: "#ffffff",
                  },
                },
              }}
            >
              <CustomCheckbox
                label={""}
                checked={isSelected}
                handleCheckboxChange={() => {}}
              />
            </MDBox>
          )}
          <MDBox
            className="blurContent"
            sx={{ display: "flex", flexDirection: "column", flexGrow: "1" }}
          >
            <MDBox position="relative">
              {photoTagsList.length > 0 && (
                <MDBox
                  display="flex"
                  position="absolute"
                  top="0"
                  width="100%"
                  alignItems="center"
                  height="70px"
                  bgColor="rgba(0, 0, 0, 0.5)"
                  zIndex={300}
                  pl={1}
                >
                  {getTags()}
                </MDBox>
              )}
              {children}
            </MDBox>
            <CardContent sx={{ padding: "13px 10px 12px 15px", flexGrow: "1" }}>
              <Grid container py={1} sx={photoSectionCardStyle}>
                <Grid>
                  <MDBox display="flex" flexDirection="column">
                    {clientMediaInfo.jobsiteId && (
                      <MDTypography
                        sx={{ ...cardContentStyle, fontSize: "12px" }}
                        variant="caption"
                      >
                        {getSiteNameNumber()}
                      </MDTypography>
                    )}
                    <MDBox sx={{ lineHeight: "18px", marginBottom: "5px" }}>
                      {getTypography(clientMediaInfo.vueName)}
                    </MDBox>
                    <MDTypography
                      sx={{ ...cardContentStyle, fontSize: "12px" }}
                      variant="caption"
                    >
                      {clientMediaInfo.address}
                    </MDTypography>
                    <MDTypography
                      sx={{ ...cardContentStyle, fontSize: "12px" }}
                      variant="caption"
                    >
                      {getTimeString()}
                    </MDTypography>
                  </MDBox>
                </Grid>
              </Grid>
            </CardContent>
          </MDBox>
        </Card>
      ) : (
        <CircularProgress color="success" sx={loaderStyle} />
      )}
    </MDBox>
  );
};

export default MediaContainer;
